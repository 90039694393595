import styled from "styled-components";

const StyleForm = styled.div`
  .form-group {
    margin-bottom: 1.5rem;
  }
  label.form-label {
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: ${(props) => props.theme.variableColor.fontBlack};
  }
  .form-control, .react-tel-input .form-control {
    font-size: 0.875rem;
    font-weight: 600;
    background: white;
    border: none;
    padding-left: 0;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0;
    &::placeholder {
      color: ${(props) => props.theme.variableColor.grayLight};
      font-weight: 300;
    }
    &:focus {
      box-shadow: none;
      border-bottom: 2px solid  ${(props) =>
        props.theme.variableColor.fontBlack};
    }
  }
  .text-muted {
    margin-top: 5px;
    display: block;
    line-height: 1rem;
    margin-bottom: 1rem;
  }
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
  /* ====================  Select Drop Down ====================  */
  
  .form-select{
    border-color: #dce2e9;
    padding: 0.6875rem 0.75rem;
    option:first-child{
      color: #A8B4C0;
    }
  }
  
  /* ====================  Custom Checkbox - Styles  ====================  */
  .custom-checkbox {
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    label:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.variableColor.primaryYellow};
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 5px;
    }
    input:checked + label::before {
      background: ${(props) => props.theme.variableColor.primaryYellow};
    }
    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 8px;
      width: 5px;
      height: 14px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  /* ====================  Filter Drop Down - Styles  ====================  */

  .filterDdMenu {
    width: 100%;
  }
  .css-14el2xx-placeholder {
    &:after {
      height: 10px;
      width: 10px;
      background: red;
    }
  }
  &.state-dropdown {
    position: relative;
    .css-4ljt47-MenuList {
      &::-webkit-scrollbar {
        background-color: white;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.variableColor.borderGray};
        border-radius: 10px;
      }
    }
    .css-yt9ioa-option input,
    .css-1n7v3ny-option input {
      margin-right: 5px;
    }
    .css-1n7v3ny-option {
      border-bottom: 1px solid #ddd;
    }
    .css-9gakcf-option,
    .css-yt9ioa-option {
      border-bottom: 1px solid #ddd;
      background-color: white;
      &:hover {
        border-bottom: 1px solid #ddd;
      }
      input {
        margin-right: 5px;
      }
      label {
        color: black;
      }
    }
    img {
      transform: translateY(65%);
      position: absolute;
      z-index: 1;
      margin-left: 15px;
    }
    .css-14el2xx-placeholder {
      color: black;
      font-weight: 700;
      font-size: 16px;
      padding-left: 30px;
      background-image: url('../../../../assets/images/filter.svg');
    }
    .css-b62m3t-container span {
      display: none;
    }
  }
.css-1nmdiq5-menu {
    z-index: 2 !important;
}

  /* ====================  Toast - Styles  ====================  */
  .toast {
    background-color: white !important;
    border: none;
    margin-top: 10px;
    padding: 15px;
    .tost-wrp {
      margin-left: 10px;
      .toast-header {
        color: black;
        font-weight: bold;
        font-size: 14px;
        padding: 0;
        border: none;
        display: flex;
        justify-content: space-between;
        .strong {
          text-transform: ;
        }
      }
      .toast-body {
        padding: 0;
      }
    }
  }


  .theme-select {
    .css-1s2u09g-control, .css-13cymwt-control, .css-t3ipsp-control, .css-1insrsq-control, .css-16xfy0z-control{
      border: none;
      min-height: auto;
      border-bottom: 1px solid #f0f0f0;
      .css-1jqq78o-placeholder{
      color: ${(props) => props.theme.variableColor.grayLight};
      font-weight: 300;
    }
    .css-1fdsijx-ValueContainer{
      padding-left: 0;
      padding-right: 0;
    }
    }
    .css-1okebmr-indicatorSeparator, .css-1u9des2-indicatorSeparator, .css-894a34-indicatorSeparator, .css-109onse-indicatorSeparator {
      display: none;
    }
    .css-6j8wv5-Input,
    .css-ackcql, .css-19bb58m, .css-jzldcf-Input, .css-1mkvw8y{
      padding: 0;
      margin: 0;
    }
    .css-319lph-ValueContainer,
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer,
    .css-1xc3v61-indicatorContainer,
    .css-1d8n9bt, .css-tlfecz-indicatorContainer, 
    .css-hlgwow, .css-15lsz6c-indicatorContainer {
      padding: 0;
    }
    .css-1pahdxg-control, .css-t3ipsp-control  {
      box-shadow: none;
      border: none;
      min-height: auto;
      border-radius: 0;
      border-bottom: 2px solid  ${(props) =>
        props.theme.variableColor.fontBlack};
    }
  }
  .regulation-editor{
    .rdw-editor-main{
        padding: 0 0.625rem;
    }
    .rdw-editor-toolbar{
        background: transparent !important;
        border-radius: 8px !important;
        border: 0 !important;
    border-bottom: 1px solid ${(props) =>
      props.theme.variableColor.borderGray};!important;
    border-radius: 0 !important;
    }
    .rdw-editor-wrapper{
        border: 1px solid  ${(props) => props.theme.variableColor.borderGray};
        border-radius: 8px !important;
        margin-top: .375rem;
    }
}
`;

export default StyleForm;
