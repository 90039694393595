import styled from 'styled-components';

const AppButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.theme.variableColor.primaryYellow};
  color: ${props => props.theme.variableColor.fontBlack};
  border: 1px solid ${props => props.theme.variableColor.primaryYellow};
  padding: 15px 30px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  // margin: 0 0 25px 0;
  transition: 200ms cubic-bezier(0.39, 0.58, 0.57, 1);
  &:hover,
  &:focus,
  &:active {
    background: ${props => props.theme.variableColor.darkYellow};
    color: ${props => props.theme.variableColor.fontBlack};
    border: 1px solid ${props => props.theme.variableColor.primaryYellow};
    cursor: pointer;
  }
   &:disabled {
    color: ${props => (props.fill ? '#ABABAB' : '#ABABAB')};
    background: ${props => (props.fill ? '#E5E5E5' : '#E5E5E5')};
    border-color: ${props => (props.fill ? '#E5E5E5' : '#E5E5E5')};
    &:hover {
      color: ${props => (props.fill ? '#ABABAB' : '#ABABAB')};
    background: ${props => (props.fill ? '#E5E5E5' : '#E5E5E5')};
    border-color: ${props => (props.fill ? '#E5E5E5' : '#E5E5E5')};
    }
  }

`;

export default AppButton;
