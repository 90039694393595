import { BACKEND_API_BASE_URL, BACKEND_API_BASE_URL_2 } from '../config/config';

export const BACKEND_SERVICE_PATHS = {
  USER_SERVICE: `${BACKEND_API_BASE_URL}users-service/v1/`,
  USER_SERVICE_2: `${BACKEND_API_BASE_URL_2}regulation-user-service/v1/`,
  REGULATION_SERVICE: `${BACKEND_API_BASE_URL_2}regulation-v2-service/v1/`,
  SEARCH_SERVICE: `${BACKEND_API_BASE_URL_2}regulation-v2-service/v1/regulation/search`,
  REGULATION_USER_SERVICE: `${BACKEND_API_BASE_URL_2}regulation-user-service/v1/`
};

export const BACKEND_SERVICE_RESOURCE_PATHS = {
  AUTHENTICATION: 'authentication',
  PERMISSIONS: 'permission',
  ROLES: 'role',
};

const apiRoutes = Object.freeze({
  COMMON: {},
  AUTH: {
    SIGN_IN: `${BACKEND_SERVICE_PATHS.USER_SERVICE}login`,
    SIGN_IN_V2: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}auth/login`,
    FORGET_PASSWORD: `${BACKEND_SERVICE_PATHS.USER_SERVICE_2}password/forgot-password`,
    RESET_PASSWORD: `${BACKEND_SERVICE_PATHS.USER_SERVICE_2}password/save`,
  },
  ADMIN: {
    GET_REGULATIONS: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation`,
    SYNC_REGULATION: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/sync`,
    DEACTIVATE_REGULATION: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/{regulationId}/deactivate`,
    ACTIVATE_REGULATION: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/activate`,
  },
  SEARCH_LICENCES: {
    GET_ALL_LICENCES: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/filter`,
    GET_LICENCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/{licenseId}`,
  },
  USER: {
    GET_USER_PROFILE_DETAILS: `${BACKEND_SERVICE_PATHS.USER_SERVICE}profile`,
    UPDATE_USER_PROFILE_DETAILS: `${BACKEND_SERVICE_PATHS.USER_SERVICE}user/{id}`,

    GET_USER_LIST: `${BACKEND_SERVICE_PATHS.USER_SERVICE_2}user`,
    GET_SINGLE_USER_DETAILS: `${BACKEND_SERVICE_PATHS.USER_SERVICE_2}user/{userId}`,
    UPDATE_SINGLE_USER_PASSWORD: `${BACKEND_SERVICE_PATHS.USER_SERVICE_2}user/{userId}/change_password`,
  },
  REGULATIONS: {
    // REGULATIONS: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation`,
    FOLDER: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}folder`,
    // REGULATIONS_CHILDREN: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/{childId}/child`,
    // PARENT_CITATIONS: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/{regulationId}/parent-citations`,
    FOLDER_PARENT_CITATIONS: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}folder/{folderId}/parent-citations`,
    REGULATIONS_TREE: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/{id}/tree`,
    REGULATIONS_CTREE: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/{id}/citation-tree`,
    DEFINITION: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}definition/{definition_id}`,
    GET_STATES: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/states`,
    GET_STATE_LIST: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}regulation/state-list`,
    REGULATION_UPDATE: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}citation/{id}`,
  },
  SEARCH: {
   SEARCH_HEADERS: `${BACKEND_SERVICE_PATHS.SEARCH_SERVICE}`,
    SEARCH_RESULTS: `${BACKEND_SERVICE_PATHS.REGULATION_SERVICE}`,
  }, 
  MANAGE_SUBSCRIPTIONS: {
    UPCOMING_BILLING_DETAILS: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/upcoming-billing-details`, 
    PAYMENT_HISTORY: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/history`, 
    PAYMENT_METHODS: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/card-list`, 
    PAYMENT_METHOD: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/card/{cardId}`, 
    DEFAULT_CARD: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/default-card/{cardId}`, 
    ADD_CARD: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/add-card`, 
    CANCEL_SUBSCRIPTION: `${BACKEND_SERVICE_PATHS.REGULATION_USER_SERVICE}payment/cancel-subscription`,
  }
});

export default apiRoutes;
