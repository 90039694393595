import { createSlice } from '@reduxjs/toolkit';
import reducerTypes from '../reducerTypes';

const initialState = {
  dataTree: [],
  selectedTreeNode: [],
  loadingSearchResultsCount: false,
  showSearchResultsCount: false,
  searchResultsCounterData: {},
};

export const sidebarSlice = createSlice({
  name: reducerTypes.sidebar,
  initialState,
  reducers: {
    SET_DATA_TREE: (state, data) => {
      state.dataTree = data.payload;
    },
    SET_SELECTED_TREE_NODE: (state, data) => {
      state.selectedTreeNode = data.payload;
    },
    SET_LOADING_SEARCH_RESULTS_COUNTS: (state, data) => {
      state.loadingSearchResultsCount = data.payload
    },
    SET_SHOW_SEARCH_RESULTS_COUNT: (state, data) => {
      state.showSearchResultsCount = data.payload
    },
    SET_SEARCH_RESULTS_COUNTER_DATA: (state, data) => {
      state.searchResultsCounterData = data.payload
    },
    CLEAR_SIDEBAR_REDUCER: (state, data) => initialState
  }
});

export const { 
  SET_DATA_TREE, 
  SET_SELECTED_TREE_NODE, 
  SET_LOADING_SEARCH_RESULTS_COUNTS,
  SET_SHOW_SEARCH_RESULTS_COUNT,
  SET_SEARCH_RESULTS_COUNTER_DATA, 
  CLEAR_SIDEBAR_REDUCER 
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
