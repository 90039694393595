export default Object.freeze({
  ENV: {
    DEVELOPMENT: 'development',
    TEST: 'test',
    PRODUCTION: 'production',
  },
  USER_ENV: {
    DEVELOPMENT: 'development',
    QA: 'qa',
    STAGING: 'staging',
    PRODUCTION: 'production',
  },
  TOAST_ALERT_TYPES: {
    SUCCESS: {
      type: 'success',
      icon: 'fa fa-check',
      cssClass: 'success',
    },
    INFO: {
      type: 'info',
      icon: 'fa fa-info-circle',
      cssClass: 'info',
    },
    WARNING: {
      type: 'warning',
      icon: 'fa fa-exclamation-triangle',
      cssClass: 'warning',
    },
    ERROR: {
      type: 'danger',
      icon: 'fa fa-times',
      cssClass: 'danger',
    },
  },
  NAVIGATIN_HEADER_TEXT: {
    SEARCH_LICENSES: 'Search Licenses',
    DATA_DICTONARY: 'Data Dictionary',
    FAQ: 'FAQ',
  },
  CARD_TYPES: ['visa', 'mastercard', 'amex', 'discover'],
  ADD_CARD_MODAL: {
    TITLE: 'Subscription',
    BILLABLE_LABEL_ONE: 'Amount (Per month)',
    BILLABLE_LABEL_TWO: 'Access to all local jurisdictions regulations',
    INFO_DESCRIPTION: 'By checking the box (below), you understand and agree that your credit card will be charged automatically  by Regulation Search every month for your service fee. This automatic charge will continue  until you cancel auto pay or you cancel your services. You may cancel auto pay by contacting Regulation Search support. If your card changes, please notify us of the new card information so that auto pay can continue.',
    TERMS_AND_SERVICES: 'I have agreed to the Terms of Services',
    SUBMIT_BUTTON_LABEL: 'Add Card'
  },
  MONTHS: [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ],
  COMPANY_STATUS: {
    PENDING: 0, 
    ACTIVE: 2
  },
  USER_GROUP: {
    MASTER_ADMIN: 1,
    LICENSE_OPERATOR_MASTER_ADMIN: 2,
    LICENSE_OPERATOR_MANAGER: 3,
    LICENSE_OPERATOR_EMPLOYER: 4,
    COMPLIANCE_COMPANY_MASTER_ADMIN: 5,
    COMPLIANCE_COMPANY_INSPECTOR: 6,
    GOVERNMENT_ENTITY_MASTER_ADMIN: 7,
    GOVERNMENT_ENTITY_INSPECTOR: 8
  },
  SORTING: {
    ASCENDING: 1,
    DESCENDING: -1
  }
});
