import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppInput from '../common/input/AppInput';
import AppButton from '../styles/ButtonLink.styled';
import LoginHelper from '../../helpers/features/LoginHelper';
import { useAuth } from '../../containers/auth/AuthContext';
import ErrorNotification from '../common/errorNotification/ErrorNotification';

function LoginForm() {
  const auth = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const loginSchema = yup.object().shape({
    password: yup.string().required('Password Required'),
    email: yup
      .string()
      .email('Please enter a valid Email')
      .required('Email Required')
  });

  return (
    <>
      <center>
        <h5 className="mb-3">Sign In</h5>
      </center>
      <Formik
        enableReinitialize
        initialValues={{ email: '', password: '' }}
        onSubmit={async (values) => {
          auth.handleLogin(values);
        }}
        validationSchema={loginSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          // eslint-disable-next-line no-unused-vars
          validateOnMount,
          dirty
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <AppInput
              label={LoginHelper.LOGIN_FORM_EMAIL_LABLE}
              requied="*"
              type="email"
              name="email"
              placeholder={LoginHelper.LOGIN_FORM_EMAIL_PLACEHOLDER}
              helpText=""
              helpTextLink=""
              linkUrl=""
              onChangeInput={handleChange}
              onBlurInput={handleBlur}
              value={values.email}
              errorText={errors.email && touched.email ? errors.email : null}
            />
            <div className="position-relative">
              <AppInput
                label={LoginHelper.LOGIN_FORM_PASSWORD_LABLE}
                requied="*"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder={LoginHelper.LOGIN_FORM_PASSWORD_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.password}
                errorText={
                  errors.password && touched.password ? errors.password : null
                }
              />
              <button
                className="rf-password-view-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            <div className="password-reset">
              <Link className="app-link" to="/forgot-password">
                {LoginHelper.FORGET_PASSOWORD_HEADER} ?
              </Link>
            </div>
            <div className="d-grid">
              <AppButton
                className="mt-2 mb-2"
                type="submit"
                disabled={!(dirty && isValid)}
              >
                {LoginHelper.LOGIN_BTN_TEXT}
              </AppButton>
              <p class="my-2 separate">or</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open(process.env.REACT_APP_SIMPLIFYA_URL);
                }}
                className="outline-btn mt-2 mb-2"
              >
                Continue with Simplifya
              </button>
            </div>
            {auth.message.type === 'error' && (
              <ErrorNotification errorMessage={auth.message.message} />
            )}
          </Form>
        )}
      </Formik>
      <center className="mt-2">
        <p>
          Don’t have an account?
          <a
            className="app-link ms-1"
            href={process.env.REACT_APP_REGFINDER_ONBOARD_URL}
            target="_blank"
            rel="noreferrer"
          >
            Sign Up
          </a>
        </p>
      </center>
    </>
  );
}

export default LoginForm;
