/* eslint-disable eqeqeq */
import axios from 'axios';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import ResponseCodes from '../../helpers/responseCodes';

// import store from '../../store/store';
// import * as authActions from '../../store/actions/auth';
import * as logs from '../../utils/logs';

const BEARER = 'Bearer';

const axiosInstance = axios.create();

// const token = () => USER_ENV === common.USER_ENV.DEVELOPMENT ? ACCESS_TOKEN : localStorage.getItem('token');
const token = () => localStorage.getItem('token');

const detachAccessToken = (config = {}) =>
  !!(
    Object.prototype.hasOwnProperty.call(config, 'detachAccessToken') &&
    config.detachAccessToken
  );

const detachRoleToken = (config = {}) =>
  !!(
    Object.prototype.hasOwnProperty.call(config, 'detachRoleToken') &&
    config.detachRoleToken
  );

const requestHandler = (request) => {
  if (!detachAccessToken(request)) {
    logs.debug('access token attached');
    request.headers.Authorization = `${BEARER} ${token()}`;
  }
  if (!detachRoleToken(request)) {
    // logs.debug('Role token attached');
    // request.headers['role-token'] = `${BEARER} ${localStorage.getItem('token')}`;
  }
  // request.headers['company_id'] = 12;
  return request;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

const successHandler = response => {
  const currentUserId = localStorage.getItem("user_id");
  const currentMasterUserGroupId = localStorage.getItem("master_user_group_id");
  if (
    !isNil(response.headers.user_id) && (!currentUserId || currentUserId != response.headers.user_id)
  ) {
    localStorage.setItem('user_id', response.headers.user_id);
  }

  if (!currentMasterUserGroupId || !isEqual(response.headers.master_user_group_id, currentMasterUserGroupId)) {
    localStorage.setItem("master_user_group_id", response.headers.master_user_group_id);
  }
  return response;
};

const errorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response?.status === ResponseCodes.ERROR.UNAUTHORIZED.CODE) {
      logs.info('User is unauthorized');
      localStorage.removeItem('token');
      window.location.href = '/login';
      // store.dispatch(authActions.tokenExpired());
    } else if (error.response?.status === ResponseCodes.ERROR.FORBIDDEN.CODE) {
      logs.info('User is unauthorized (403)');
      localStorage.removeItem('token');
      window.location.href = '/login';
    } else if (
      error.response?.status === ResponseCodes.ERROR.INTERNAL_SERVER_ERROR.CODE
    ) {
      logs.info('Internal server error');
      // store.dispatch(
      //   commonActions.showErrorAlert({ message: ResponseCodes.ERROR.INTERNAL_SERVER_ERROR.MSG }),
      // );
    } else if (
      error.response?.status === ResponseCodes.ERROR.VALIDATION_ERROR.CODE
    ) {
      logs.info('Validation error');
      // store.dispatch(commonActions.showErrorAlert({ message: extractErrorMsg(error) }));
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const get = (url, config) => axiosInstance.get(url, config);

export const post = (url, body, config) =>
  axiosInstance.post(url, body, config);

export const put = (url, body, config) => axiosInstance.put(url, body, config);

export const patch = (url, body, config) =>
  axiosInstance.patch(url, body, config);

export const del = (url, config) => axiosInstance.delete(url, config);

export const APIReqCancelSource = () => axios.CancelToken.source();
