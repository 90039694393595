import { createSlice } from '@reduxjs/toolkit';
import reducerTypes from '../reducerTypes';

const initialState = {
  isTokenExpired: false,
  token: null,
};

export const authSlice = createSlice({
  name: reducerTypes.auth,
  initialState,
  reducers: {
    SET_TOKEN_EXPIRY_STATUS: (state, data) => {
      state.isTokenExpired = data.payload;
    },
    SET_TOKEN: (state, data) => {
      state.token = data.payload;
    },
  },
});

export const { SET_TOKEN_EXPIRY_STATUS, SET_TOKEN } = authSlice.actions;

export default authSlice.reducer;
