import React from 'react';
import ForgetPasswordForm from '../../components/auth/ForgetPasswordForm';
import Authlayout from '../../components/layouts/auth/AuthLayout';
import LoginHelper from '../../helpers/features/LoginHelper';

function ForgotPassword() {
  return (
    <Authlayout
      mainHeader={LoginHelper.FORGET_PASSOWORD_HEADER}
      subHeader={LoginHelper.FORGET_PASSOWORD_SUBHEADER}
    >
      <ForgetPasswordForm />
    </Authlayout>
  );
}

export default ForgotPassword;
