import styled from "styled-components";

const StyleLogin = styled.div`
align-items: start;
    display: flex;
    height: 100vh;
  .login-wrap {
    background: white;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 20px 35px -8px rgb(161 176 205 / 25%);
  }
  img {
    margin-bottom: 25px;
  }
  h1 {
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 11px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }
  div.password-reset {
    text-align: right;
  }
  .confirm-password {
    margin-top: 25px;
  }

  h5{
    font-size: 1.375rem;
    font-weight: 700;
  }
  .separate {
    position: relative;
    max-width: 500px;
    text-align: center;
  
    &:before,
    &:after {
      content: " ";
      height: 1px;
      width: 160px;
      background: ${props => props.theme.variableColor.borderGray};
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  
    &:before {
      left: 0;
    }
  
    &:after {
      right: 0;
    }
  }
  
`;
export default StyleLogin;
