const theme = {
  variableColor: {
    primaryYellow: '#FDC126',
    darkYellow: '#e5ae07',
    primaryGreen: '#5DADAE',
    borderGray: '#EBEAE8',
    primaryBlue: '#1682FA',
    fontBlack: '#36475A',
    grayLight: '#B8C1CB',
    grayBlue: '#95A3B4',
  },
};

export default theme;
