import { post } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';

export const login = async body => {
  try {
    const url = apiRoutes.AUTH.SIGN_IN;
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const { data } = await post(url, body, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error;
  }
};

export const loginV2 = async body => {
  try {
    const url = apiRoutes.AUTH.SIGN_IN_V2;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const forgetPassword = async params => {
  try {
    const url = apiRoutes.AUTH.FORGET_PASSWORD;
    const { data } = await post(url, params);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const resetPassword = async params => {
  try {
    const url = apiRoutes.AUTH.RESET_PASSWORD;
    const { data } = await post(url, params);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};
