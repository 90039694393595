/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Image } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import common from '../../../helpers/common';
import { useAuth } from '../../../containers/auth/AuthContext';

import { AppNav } from '../../layouts/header/Header.styled';
import { showProfileSidePanel } from '../../../store/actions/profile';
import MenuIcon from '../../../assets/images/menu.svg';
import routes from '../../../helpers/routes';

function UserAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const userGroupId = localStorage.getItem('master_user_group_id');

  const [adminView, setAdminView] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('admin')) setAdminView(true);
    else setAdminView(false);
  }, [location]);

  const logout = () => {
    auth.handleLogout();
  };

  return (
    <AppNav className="d-flex align-items-center">
      <Dropdown className="user-action-dropdown">
        <Dropdown.Toggle variant="" className="link-btn" id="dropdown-basic">
          Menu <Image src={MenuIcon} alt="Menu" className="me-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => dispatch(showProfileSidePanel())}>
            User Profile
          </Dropdown.Item>
          {parseInt(userGroupId) === common.USER_GROUP.MASTER_ADMIN && (
            <Dropdown.Item
              onClick={() =>
                navigate(
                  adminView
                    ? routes.AUTHENTICATED.ROOT.PATH
                    : routes.AUTHENTICATED.ADMIN.REGULATIONS.PATH
                )
              }
            >
              {adminView ? 'Dashboard' : 'Admin Panel'}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
        {/* <Link onClick={logout}>Logout</Link> */}
      </Dropdown>
    </AppNav>
  );
}

export default UserAction;
