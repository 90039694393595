// development | testing | production
export const ENV = process.env.NODE_ENV;

// development | qa | staging | production
export const USER_ENV = process.env.REACT_APP_USER_ENV;

// true | false
export const LOGS_ENABLED = process.env.REACT_APP_LOGS_ENABLED || false;

// backend API
// ex: https://billing-dev-api.empyreallogistics.com
export const BACKEND_API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;
export const BACKEND_API_BASE_URL_2 = process.env.REACT_APP_BACKEND_API_BASE_URL_2;

export const FIREBASE = {};

export const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;

export const STRIPE_TOKEN = process.env.REACT_APP_STRIPE_TOKEN;

export const SIMPLIFYA_BASE_URL = process.env.REACT_APP_SIMPLIFYA_BASE_URL;

export const GOOGLE_GA4_TOKEN = process.env.REACT_APP_GOOGLE_GA4_KEY;
