import * as profileReducer from '../reducers/profile';

export const showProfileSidePanel = () => dispatch => {
    dispatch(profileReducer.OPEN_PROFILE_SIDE_PANEL());
};

export const closeProfileSidePanel = () => dispatch => {
    dispatch(profileReducer.CLOSE_PROFILE_SIDE_PANEL());
};

export const setCurrentTab = (tab) => dispatch => {
    dispatch(profileReducer.SET_CURRENT_TAB(tab));
};