import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';

import SearchInput from '../../searchInput/SearchInput';

import UserAction from '../../common/userAction/UserAction';
import {
  clearSelectedFilter,
  setSearchClearState,
  setSearchSideBar
} from '../../../store/actions/search';
import { setShowSearchResultsCount } from '../../../store/actions/sidebar';

import { StyleHeader } from './Header.styled';
import Logo from '../../../assets/images/logo.svg';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const { selectedFilter, selectedState } = useSelector((state) => ({
    selectedFilter: state.SEARCH.selectedFilter,
    selectedState: state.SEARCH.selectedState
  }));

  const [adminView, setAdminView] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    params.searchTerm ?? selectedFilter?.keyword ?? ''
  );

  useEffect(() => {
    if (location.pathname.includes('admin')) setAdminView(true);
    else setAdminView(false);
  }, [location]);

  useEffect(() => {
    if (params.searchTerm) {
      setSearchTerm(params.searchTerm);
    } else if (selectedFilter && selectedFilter.keyword) {
      setSearchTerm(selectedFilter.keyword);
    } else {
      setSearchTerm('');
      dispatch(setShowSearchResultsCount(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, selectedFilter]);

  const handleSearchTerm = (e) => {
    if (e.keyCode === 13) {
      navigateToSearch();
    } else {
      setSearchTerm(e.target.value);
    }
  };

  const navigateToSearch = () => {
    if (searchTerm) {
      dispatch(clearSelectedFilter());
      dispatch(setSearchSideBar(true));
      navigate(`/search-result/${searchTerm}`);
    } else {
      closeSearchPanel();
    }
  };

  const closeSearchPanel = async () => {
    dispatch(clearSelectedFilter());
    dispatch(setSearchClearState(true));
    dispatch(setSearchSideBar(false));
    dispatch(setShowSearchResultsCount(false))
    navigate(`/`);
  };

  return (
    <StyleHeader>
      <Navbar
        fixed="top"
        bg=""
        variant="white"
        className="main-nav p-0"
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <Image src={Logo} alt="Cannabis Regulation Kibrary Logo" />
          </Navbar.Brand>
          {!adminView && (
            <Nav className="m-auto d-none d-sm-block">
              <SearchInput
                className="me-2"
                value={searchTerm}
                onChange={handleSearchTerm}
                onClick={navigateToSearch}
                onClear={closeSearchPanel}
                disabled={!selectedState || selectedState.length <= 0}
              />
            </Nav>
          )}
          <div className="user-action">
            <UserAction />
          </div>
        </Container>
      </Navbar>
    </StyleHeader>
  );
}

export default Header;
