/* eslint-disable no-console */
import moment from 'moment-timezone';

import { isLogsEnabled } from './common';

const log = (...args) => {
  if (isLogsEnabled()) {
    // console.log(moment().format(), args);
  }
};
const debug = (...args) => {
  if (isLogsEnabled()) {
    console.debug(moment().format(), args);
  }
};

const info = (...args) => {
  if (isLogsEnabled()) {
    console.info(moment().format(), args);
  }
};

const warn = (...args) => {
  if (isLogsEnabled()) {
    console.warn(moment().format(), args);
  }
};

const error = (...args) => {
  if (isLogsEnabled()) {
    console.error(moment().format(), args);
  }
};

export { log, debug, info, warn, error };
