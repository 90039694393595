import styled from "styled-components";

export const StyledAdminSideBar = styled.div`
  text-align: center;
  top: 85px;
  box-shadow: 0 19px 43px rgb(161 176 205 / 26%);
  background: #fff;
  padding: 20px 10px;
  width: 100%;
  bottom: 0;
  z-index: 2;
  position: fixed;
  left: 0;
  transition: width 0.25s ease, margin 0.25s ease;
  max-height: calc(100vh - 85px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  a {
    margin-bottom: 50px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    color: ${(props) => props.theme.variableColor.fontBlack};
    .active-regulations, .active-user{
      display: none;
    }
    &.active{
      .active-regulations, .active-user{
        display: inline-block;
      }
      .regulations, .user-icon{
        display: none;
      }
    }
    span{
      display: block;
    }
  }
  @media (max-width: 575.98px) {
    top: 145px;
  }
  @media (min-width: 992px) {
    width: 8%;
  }
  @media (min-width: 1700px) {
    width: 100px;
  }
`;
