import React from 'react';
import { Container } from 'react-bootstrap';

import { MyProfileContextProvider } from './MyProfileContext';
import { StylePage } from '../../components/styles/Page.styled';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import MyProfileHelper from '../../helpers/features/MyProfileHelper';
import MyProfileForm from '../../components/myProfile/MyProfileForm';

function MyProfile(props) {
  return (
    <StylePage>
      <Container>
        <MyProfileContextProvider componentProps={props}>
          <PageHeader
            title={MyProfileHelper.MY_PROFILE_HEADER}
            subheader={MyProfileHelper.MY_PROFILE_SUBHEADER}
          />
          <MyProfileForm />
        </MyProfileContextProvider>
      </Container>
    </StylePage>
  );
}

export default MyProfile;
