const SearchLicenseHelper = Object.freeze({
  HEMP_LICENSES_TABLE_HEADERS: [
    { label: 'License Number', key: 1, db_key: 'license_number' },
    { label: 'Legal Name', key: 2, db_key: 'legal_name' },
    { label: 'DBA', key: 3, db_key: 'dba_name' },
    { label: 'Location Address', key: 4, db_key: ['street', 'city'] },
    { label: 'License Issuer', key: 5, db_key: 'license_issuer' },
    { label: 'Type', key: 6, db_key: 'license_type' },
    { label: 'License Status', key: 7, db_key: 'status' },
    { label: '', key: 8 },
  ],
  PAGE_HEADER: 'Hemp Licenses',
  GET_LICENSE_RECORDS_DEFAULT_PARAMETERS: {
    page: 1,
    limit: 10,
    asc: ['license_number'],
  },
  NO_VALUES: '-',
  LICENSE_DETAILS_LABLES: [
    { LABEL: 'License Number', VALUE: 1 },
    { LABEL: 'License Issuer', VALUE: 2 },
    { LABEL: 'License Designation', VALUE: 3 },
    { LABEL: 'License Type', VALUE: 4 },
    { LABEL: 'License Details', VALUE: 5 },
    { LABEL: 'License Issue Date', VALUE: 6 },
    { LABEL: 'License Expiration Date', VALUE: 7 },
    { LABEL: 'License Status', VALUE: 8 },
    { LABEL: 'Legal Name', VALUE: 9 },
    { LABEL: 'DBA Name', VALUE: 10 },
    { LABEL: 'Location Address', VALUE: 11 },
    { LABEL: 'Company Contact', VALUE: 12 },
    { LABEL: 'Company Email', VALUE: 13 },
    { LABEL: 'Company Phone', VALUE: 14 },
    { LABEL: 'Company Website', VALUE: 15 },
    { LABEL: 'County', VALUE: 16 },
    { LABEL: 'State Name', VALUE: 17 },
    { LABEL: 'Last Updated', VALUE: 18 },
    { LABEL: 'Notes', VALUE: 19 },
  ],
  LICENSE_DETAILS_PAGE_COLUMN_COUNT: 12,
  LICENSE_RECORDS_STARTING_PAGE: 1,
  LICENSE_RECORDS_DEFAULT_LIMIT: 10,
  RECORDS_PER_PAGES_VALUES: [
    { value: 10, key: 1 },
    { value: 20, key: 2 },
    { value: 50, key: 3 },
    { value: 100, key: 4 },
  ],
  DEFAULT_SORTING_PARAMETER: 'license_number',
  EMPTY_LICENSE_DEFAULT_MESSAGE: 'Please select at least one state to see the regulations.',
  NO_REGULATIONS_FOUND: 'No results found.'
});

export default SearchLicenseHelper;
