import styled from 'styled-components';

export const StyleDashboard = styled.div`
  // h2 {
  //   padding: 30px 0 120px 0;
  // }
  .item-row{
    margin-top: -120px;
    }
  }
  .card {
    padding: 20px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 20px 35px -8px rgb(161 176 205 / 25%);
    margin-bottom: 20px;
    min-height: calc(100vh - 225px);
  }
  @media (max-width: 575.98px) {
    .card{
        box-shadow: 0 30px 35px 22px rgb(161 176 205 / 25%);
        margin-bottom: 20px !important;
        min-height:auto;
        height: auto;
    }
}
`;

