import React from 'react';
import ResetPasswordForm from '../../components/auth/ResetPassword';
import Authlayout from '../../components/layouts/auth/AuthLayout';
import LoginHelper from '../../helpers/features/LoginHelper';

function ResetPassword() {
  return (
    <Authlayout
      mainHeader={LoginHelper.RESET_PASSWORD_HEADER}
      subHeader={LoginHelper.RESET_PASSOWORD_SUBHEADER}
    >
      <ResetPasswordForm />
    </Authlayout>
  );
}

export default ResetPassword;
