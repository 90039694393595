import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MainWrap from '../../../containers/main-wrap/MainWrap';
import Header from '../header/Header';
import SideBar from '../../common/sidebar/SideBar';
import AdminSideBar from '../../admin/sidebar/AdminSidebar';

function Main() {
  const { selectedFilter } = useSelector((state) => ({
    selectedFilter: state.SEARCH.selectedFilter
  }));

  const location = useLocation();

  const [adminView, setAdminView] = useState(false);

  useEffect(() => {
    if(location.pathname.includes('admin'))
      setAdminView(true);
    else setAdminView(false);
  }, [location])

  return (
    <>
      <Header />
      <MainWrap
        customClass={`flex-wrap-reverse d-flex ${
          selectedFilter?.keyword
            ? 'search-sidebar-open align-items-center'
            : ''
        }`}
      >
        {adminView ? <AdminSideBar /> : <SideBar />}
        <Outlet />
      </MainWrap>
    </>
  );
}

export default Main;
