import React from "react";
import { Form, Button, Image } from "react-bootstrap";
import SearchIcon from "../../assets/images/search.svg";
import CloseIcon from '../../assets/images/cancel.svg';
import { StyleSearch } from "./Search.styled";

function SearchInput({value, onChange, className, onClick, onClear, disabled}) {
  return (
    <StyleSearch>
      <Form className="d-flex" onSubmit={e => e.preventDefault()}>
        <Form.Control
          type="text"
          placeholder="Search"
          className={className}
          aria-label="Search"
          value={value}
          onChange={onChange}
          onKeyDown={onChange}
          disabled={disabled}
        />
        {value && <Button variant="" className="clear-btn" onClick={onClear}>
          <Image src={CloseIcon} />
        </Button>}
        <Button variant="" className="search-btn" onClick={onClick}>
          <Image src={SearchIcon} />
        </Button>
      </Form>
    </StyleSearch>
  );
}

export default SearchInput;
