import { v1 as uuidv1 } from 'uuid';

import * as commonReducer from '../reducers/common';
import commonHelper from '../../helpers/common';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

/* -------------------------------------------------------------------------- */
/*                                 Toast alert                                */
/* -------------------------------------------------------------------------- */
export const showToastAlert = data => dispatch => {
  data.id = uuidv1();
  data.show = true;
  dispatch(commonReducer.SHOW_TOAST_ALERT(data));
};

export const hideToastAlert = id => dispatch => {
  dispatch(commonReducer.HIDE_TOAST_ALERT(id));
};

export const showSuccessAlert = data => dispatch => {
  data.type = commonHelper.TOAST_ALERT_TYPES.SUCCESS.type;
  dispatch(showToastAlert(data));
};

export const showInfoAlert = data => dispatch => {
  data.type = commonHelper.TOAST_ALERT_TYPES.INFO.type;
  dispatch(showToastAlert(data));
};

export const showWarningAlert = data => dispatch => {
  data.type = commonHelper.TOAST_ALERT_TYPES.WARNING.type;
  dispatch(showToastAlert(data));
};

export const showErrorAlert = data => dispatch => {
  data.type = commonHelper.TOAST_ALERT_TYPES.ERROR.type;
  dispatch(showToastAlert(data));
};

export const storeSelectedRegulationData = data => dispatch => {
  dispatch(commonReducer.STORE_SELECTED_REGULATION_DATA(data));
};

export const clearSelectedRegulationData = data => dispatch => {
  dispatch(commonReducer.CLEAR_SELECTED_REGULATION_DATA());
};
/* -------------------------------------------------------------------------- */
/*                             Confirmation popup                             */
/* -------------------------------------------------------------------------- */
// export const showConfirmationPopup = data => dispatch => {
//   dispatch(commonReducer.SHOW_CONFIRMATION_POPUP(data));
// };

// export const hideConfirmationPopup = () => dispatch => {
//   dispatch(commonReducer.HIDE_CONFIRMATION_POPUP());
// };

// export const showInfoConfirmationPopup = data => dispatch => {
//   data.type = commonHelper.CONFIRMATION_POPUP_TYPES.INFO.type;
//   dispatch(commonReducer.SHOW_CONFIRMATION_POPUP(data));
// };

// export const showWarningConfirmationPopup = data => dispatch => {
//   data.type = commonHelper.CONFIRMATION_POPUP_TYPES.WARNING.type;
//   dispatch(commonReducer.SHOW_CONFIRMATION_POPUP(data));
// };
