import { Formik } from 'formik';
import { Form, Alert } from 'react-bootstrap';
import * as yup from 'yup';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoginHelper from '../../helpers/features/LoginHelper';
import ErrorNotification from '../common/errorNotification/ErrorNotification';
import AppInput from '../common/input/AppInput';
import AppButton from '../styles/ButtonLink.styled';
import { useAuth } from '../../containers/auth/AuthContext';

function ForgetPasswordForm() {
  const auth = useAuth();
  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid Email')
      .required('Email is required')
  });

  return (
    <>
      <center className="mb-4">
        <h5 className="mb-3">Forgot Your Password?</h5>
        <p>
          Don’t worry! Resetting your password is easy. Just enter the email
          linked to your RegFinder account.
        </p>
      </center>
      <Formik
        enableReinitialize
        initialValues={{ email: '' }}
        onSubmit={async (values) => {
          auth.handleForgetPassword(values);
        }}
        validationSchema={loginSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          // eslint-disable-next-line no-unused-vars
          validateOnMount,
          dirty
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <AppInput
              label={LoginHelper.LOGIN_FORM_EMAIL_LABLE}
              requied="*"
              type="email"
              name="email"
              placeholder={LoginHelper.LOGIN_FORM_EMAIL_PLACEHOLDER}
              helpText=""
              helpTextLink=""
              linkUrl=""
              onChangeInput={handleChange}
              onBlurInput={handleBlur}
              value={values.email}
              errorText={errors.email && touched.email ? errors.email : null}
              disabled={auth.isSendingEmail}
            />
            <div className="d-grid">
              <AppButton
                type="submit"
                disabled={!(dirty && isValid) || auth.isSendingEmail}
              >
                {LoginHelper.FORGET_PASSWORD_BTN_TEXT}
              </AppButton>
              <p className="text-center mt-2">
                {LoginHelper.FORGET_PASSWORD_BACK_LINK_TEXT} <br />
                <Link className="app-link ms-1" to="/login">
                  Try Sign In
                </Link>
              </p>
            </div>
            {auth.message.type === 'error' && (
              <ErrorNotification errorMessage={auth.message.message} />
            )}
          </Form>
        )}
      </Formik>
      {auth.showForgotPasswordAlert && (
        <Alert
          className="mt-2"
          variant="success"
          onClose={() => auth.setShowForgotPasswordAlert(false)}
          dismissible
        >
          <p>
            If the email address entered matches an account in our database,
            we’ve sent a password reset email to it.
          </p>
        </Alert>
      )}
    </>
  );
}

export default ForgetPasswordForm;
