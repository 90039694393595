/* eslint-disable import/prefer-default-export */
import * as sidebarReducer from '../reducers/sidebar';

export const setDataTree = data => dispatch => {
  dispatch(sidebarReducer.SET_DATA_TREE(data))
};

export const setSelectedTreeNode = data => dispatch => {
  dispatch(sidebarReducer.SET_SELECTED_TREE_NODE(data));
};

export const setLoadingSearchResultsCounts = data => dispatch => {
  dispatch(sidebarReducer.SET_LOADING_SEARCH_RESULTS_COUNTS(data))
};

export const setShowSearchResultsCount = data => dispatch => {
  dispatch(sidebarReducer.SET_SHOW_SEARCH_RESULTS_COUNT(data))
};

export const setSearchResultsCounterData = data => dispatch => {
  dispatch(sidebarReducer.SET_SEARCH_RESULTS_COUNTER_DATA(data))
};

export const clearSidebarReducer = () => dispatch => {
  dispatch(sidebarReducer.CLEAR_SIDEBAR_REDUCER())
};