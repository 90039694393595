import React from 'react';
import PropTypes from 'prop-types';
import { StylePageHeader } from '../../styles/Page.styled';

function PageHeader(props) {
  const { title, subheader, className } = props;
  return (
    <StylePageHeader>
      <h2 className={className}>{title}</h2>
      <h6 className="m-0">{subheader}</h6>
    </StylePageHeader>
  );
}

PageHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  className: null,
};

export default PageHeader;
