export default Object.freeze({
  UN_AUTHENTICATED: {
    LOGIN: {
      PATH: 'login'
    },
    LOGIN_REDIRECT: {
      PATH: 'not-found'
    },
    FORGET_PASSWORD: {
      PATH: 'forgot-password'
    },
    RESET_PASSWORD: {
      PATH: 'reset-password/:token'
    },
    REGISTER: {
      PATH: 'register/:token'
    },
    AUTHENTICATE_TOKEN: {
      PATH: 'authenticate-token'
    }
  },
  AUTHENTICATED: {
    ROOT: {
      PATH: '/'
    },
    SEARCH_LICENSES: {
      PATH: 'search-licenses'
    },
    LICENSES_DETAILS: {
      PATH: 'licenses-details/:id'
    },
    DATA_DICTIONARY: {
      PATH: 'data-dictionary'
    },
    FAQ: {
      PATH: 'faq'
    },
    MY_PROFILE: {
      PATH: 'my-profile'
    },
    MAP: {
      PATH: 'map'
    },
    SEARCH_RESULTS: {
      MAIN: {
        PATH: 'search-result'
      },
      SINGLE: {
        PATH: '/search-result/:searchTerm'
      }
    },
    ADMIN: {
      USERS: {
        PATH: 'admin/manage-users'
      },
      REGULATIONS: {
        PATH: 'admin/manage-regulations'
      }
    }
  }
});
