import React, { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './containers/auth/Login';
import ForgotPassword from './containers/auth/ForgotPassword';
import ResetPassword from './containers/auth/ResetPassword';
import Main from './components/layouts/main/Main';
import routes from './helpers/routes';
import ToastAlerts from './components/common/toastAlerts/ToastAlerts';
import MyProfile from './containers/myProfile/MyProfile';
import AuthenticationRoute from './containers/auth/AuthenticationRoute';
import {
  AuthorizedWrapper,
  UnauthorizedWrapper
} from './containers/auth/AuthenticationWrappers';

import 'react-toastify/dist/ReactToastify.css';

const Profile = lazy(() => import('./containers/profile/Profile'));
const Home = lazy(() => import('./containers/home/Home'));
const SearchResult = lazy(() =>
  import('./containers/searchResult/SearchResult')
);

// Admin Containers
const ManageUsers = lazy(() =>
  import('./containers/admin/manageUsers/ManageUsers')
);
const ManageRegulations = lazy(() =>
  import('./containers/admin/manageRegulations/ManageRegulations')
);

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        {/* Unauthenticated routes */}
        <Route element={<UnauthorizedWrapper />}>
          <Route
            path={routes.UN_AUTHENTICATED.LOGIN.PATH}
            element={<Login />}
          />
          <Route
            path={routes.UN_AUTHENTICATED.FORGET_PASSWORD.PATH}
            element={<ForgotPassword />}
          />
          <Route
            path={routes.UN_AUTHENTICATED.RESET_PASSWORD.PATH}
            element={<ResetPassword />}
          />
          <Route
            path={routes.UN_AUTHENTICATED.REGISTER.PATH}
            element={<ResetPassword />}
          />
        </Route>

        {/* Authenticated routes */}
        <Route element={<AuthorizedWrapper />}>
          <Route path={routes.AUTHENTICATED.ROOT.PATH} element={<Main />}>
            <Route
              index
              element={
                <Suspense>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path={routes.AUTHENTICATED.SEARCH_RESULTS.MAIN.PATH}
              element={
                <Suspense>
                  <SearchResult />
                </Suspense>
              }
            >
              <Route
                path={routes.AUTHENTICATED.SEARCH_RESULTS.SINGLE.PATH}
                element={
                  <Suspense>
                    <SearchResult />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={routes.AUTHENTICATED.MY_PROFILE.PATH}
              element={<MyProfile />}
            />

            {/* admin routes - start */}
            <Route
              path={routes.AUTHENTICATED.ADMIN.USERS.PATH}
              element={
                <Suspense>
                  <ManageUsers />
                </Suspense>
              }
            />
            <Route
              path={routes.AUTHENTICATED.ADMIN.REGULATIONS.PATH}
              element={
                <Suspense>
                  <ManageRegulations />
                </Suspense>
              }
            />
            {/* admin routes - end */}
          </Route>
        </Route>

        {/* Token route */}
        <Route
          path={routes.UN_AUTHENTICATED.AUTHENTICATE_TOKEN.PATH}
          element={<AuthenticationRoute />}
        />

        <Route
          path="*"
          element={<Navigate to={routes.UN_AUTHENTICATED.LOGIN.PATH} />}
        />
      </Routes>

      <Suspense>
        <Profile />
      </Suspense>

      <ToastAlerts />

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
