import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgetPassword, loginV2, resetPassword } from '../../apis/authApi';
import store, { persistor } from '../../store/store';
import * as authActions from '../../store/actions/auth';
import { clearSearchReducer } from '../../store/actions/search';
import * as commonActions from '../../store/actions/common';
import LoginHelper from '../../helpers/features/LoginHelper';
import { clearSidebarReducer } from '../../store/actions/sidebar';
import { decodeToken } from '../../utils/common';
import common from '../../helpers/common';
import { SIMPLIFYA_BASE_URL } from '../../config/config';

const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const token = localStorage.getItem('token');
  /* -------------------------------------------------------------------------- */
  /*                                   Router                                   */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const location = useLocation();

  /* -------------------------------------------------------------------------- */
  /*                                    Hooks                                   */
  /* -------------------------------------------------------------------------- */
  const [isBackendAuthorized, setIsBackendAuthorized] = useState(!!token);
  const [message, setMessage] = useState({
    type: null,
    message: null
  });

  const [showForgotPasswordAlert, setShowForgotPasswordAlert] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const handleLogin = useCallback(
    async (params) => {
      try {
        const response = await loginV2(params);
        if (response.success && response.data) {
          const { company_status, master_user_group_id } = decodeToken(response.data.token);
          // if the company_status property decoded from the token equals to 0 
          // redirect to subscribe page of regfinder simplifya
          if (company_status === common.COMPANY_STATUS.PENDING) {
            window.location.replace(`${SIMPLIFYA_BASE_URL}app-v2/regfinder/subscribe?token=${response.data.token}`)
          } else {
            localStorage.setItem('master_user_group_id', master_user_group_id)
            localStorage.setItem('token', response.data.token);
            store.dispatch(authActions.setToken(response.data.token));
            toast.success(response.message);
            setIsBackendAuthorized(true);
            navigate('/');
          }
        } else {
          toast.error(response.message);
        }
      } catch (e) {
        toast.error('Unable to authorize user from backend');
        console.error('Unable to authorize user from backend', e);
      }
    },
    [navigate]
  );

  const handleLogout = useCallback(() => {
    try {
      store.dispatch(clearSearchReducer());
      store.dispatch(clearSidebarReducer());
      localStorage.removeItem('token');
      persistor.purge();
      setIsBackendAuthorized(false);
      navigate('/login');
    } catch (e) {
      console.error('Unable to authorize user from backend', e);
    }
  }, [navigate]);

  const handleForgetPassword = useCallback(async (params) => {
    try {
      setIsSendingEmail(true);
      const response = await forgetPassword(params);
      if (!response.success) {
        toast.error(response.message);
      }
      setShowForgotPasswordAlert(true);
      setIsSendingEmail(false);
    } catch (e) {
      console.error('Unable to authorize user from backend', e);
      setIsSendingEmail(false);
    }
  }, []);

  const handleResetPassword = useCallback(
    async (values) => {
      try {
        setIsResettingPassword(true);
        const payload = {
          password: values.password,
          confirmation_code: values.token,
          set_password_type: location.pathname.includes('reset-password')
            ? 1
            : 2
        };

        const response = await resetPassword(payload);
        if (response.success) {
          toast.success(response.message);
          navigate('/login');
        } else {
          toast.error(response.message);
        }
        setIsResettingPassword(false);
      } catch (e) {
        toast.error(e);
        console.error('Unable to authorize user from backend', e);
        setIsResettingPassword(false);
      }
    },
    [navigate, location]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const persistToken = (token) => {
    localStorage.setItem('token', token);
    store.dispatch(authActions.setToken(token));
    setIsBackendAuthorized(true);
    // navigate('/');
    window.location.replace(LoginHelper.DOMAIN_BASE_URL);
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsBackendAuthorized(true);
    }
  }, [setIsBackendAuthorized]);

  /* -------------------------------------------------------------------------- */
  /*                                Return values                               */
  /* -------------------------------------------------------------------------- */
  const value = useMemo(
    () => ({
      isBackendAuthorized,
      message,
      handleLogin,
      handleLogout,
      handleForgetPassword,
      handleResetPassword,
      persistToken,

      isResettingPassword,
      showForgotPasswordAlert,
      setShowForgotPasswordAlert,
      isSendingEmail,
      setIsSendingEmail
    }),
    [
      isBackendAuthorized,
      message,
      handleLogin,
      handleLogout,
      handleForgetPassword,
      handleResetPassword,
      persistToken,
      isResettingPassword,
      showForgotPasswordAlert,
      setShowForgotPasswordAlert,
      isSendingEmail,
      setIsSendingEmail
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AuthContextProvider, useAuth };
