import styled from "styled-components";

const StyleTreeView = styled.div`
.ant-tree .ant-tree-treenode{
    width: 100%;
}
.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
    background: white;
    width: 100%;
    border-radius: 5px;
}
.ant-tree-show-line .ant-tree-switcher{
    background: transparent;
    z-index: 1;
}
span.ant-tree-switcher.ant-tree-switcher-noop {
    position: relative !important;
    left: 0;
}
.ant-tree .ant-tree-node-content-wrapper{
    min-height: 30px;
    line-height: 30px;
    padding: 0 5px 0 10px;
    width: 100%;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background: ${props => props.theme.variableColor.primaryYellow};
}
.ant-tree .ant-tree-node-content-wrapper:hover{
    background: #ffefc7;
    width: 100%;
    border-radius: 5px;
}
.text-editor-wrp p, .text-editor-wrp h1, .text-editor-wrp h2, .text-editor-wrp h3, .text-editor-wrp h4, .text-editor-wrp h5, .text-editor-wrp h6{
    display: inline;
}
.result-count{
    background: #E7F2FE;
    border-radius: 30px;
    padding: 5px 10px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: ${props => props.theme.variableColor.primaryBlue};
}
`;
export default StyleTreeView;
