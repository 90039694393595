import React from 'react';
import Authlayout from '../../components/layouts/auth/AuthLayout';
import LoginForm from '../../components/auth/LoginForm';

function Login() {
  return (
    <Authlayout>
      <LoginForm />
    </Authlayout>
  );
}

export default Login;
