import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { Tree, Skeleton } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import size from "lodash/size";
import { v1 as uuidv1 } from "uuid";

import {
  setStates,
  setSearchSideBar,
  setSelectedState,
  setRegulationList,
  setIsLoadingRegTree,
  clearSelectedFilter,
} from "../../../store/actions/search";
import {
  setDataTree,
  setSelectedTreeNode,
} from "../../../store/actions/sidebar";

import {
  getStateList,
  getFolderData,
  getParentCitationData,
} from "../../../apis/regulationsApi";

import CheckMultiSelect from "../checkMultiSelect/CheckMultiSelect";
import SearchLicenseHelper from "../../../helpers/features/SearchLicensesHelper";

import StyleTreeView from "../../../components/dashboard/TreeView.styled";
import { StyleSideBar } from "./SideBar.styled";

function SideBar() {
  const {
    states,
    statesObj,
    selectedState,
    isSearchClear,
    searchSideBar,
    selectedFilter,
    dataTree,
    selectedTreeNode,
    loadingSearchResultsCount,
    searchResultsCounterData,
    showSearchResultsCount,
  } = useSelector((state) => ({
    states: state.SEARCH.states,
    statesObj: state.SEARCH.statesObj,
    selectedState: state.SEARCH.selectedState,
    isSearchClear: state.SEARCH.isSearchClear,
    regulationList: state.SEARCH.regulationList,
    searchSideBar: state.SEARCH.searchSideBar,
    selectedFilter: state.SEARCH.selectedFilter,
    dataTree: state.SIDEBAR.dataTree,
    selectedTreeNode: state.SIDEBAR.selectedTreeNode,
    loadingSearchResultsCount: state.SIDEBAR.loadingSearchResultsCount,
    searchResultsCounterData: state.SIDEBAR.searchResultsCounterData,
    showSearchResultsCount: state.SIDEBAR.showSearchResultsCount,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states
  const [isStatesLoading, setIsStatesLoading] = useState(false);

  const [isDataTreeLoading, setIsDataTreeLoading] = useState(false);
  const [loadedNodes, setLoadedNodes] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState([]);

  // useEffects
  useEffect(() => {
    // if (!states || isEmpty(states)) {
    fetchStates();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateArray(selectedState);
    if (selectedState && selectedState.length > 0) {
      getFolderHeaders(selectedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (selectedTreeNode && selectedTreeNode[0]) {
      setSelectedNodeKey([selectedTreeNode[0].id]);
      if (
        !selectedTreeNode[0].pos &&
        dataTree &&
        dataTree[0] &&
        dataTree[0].children &&
        dataTree[0].children[0]
      ) {
        setSelectedKeys([dataTree[0].id, dataTree[0].children[0].id]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTreeNode]);

  useEffect(() => {
    if (isSearchClear) {
      setSelectedKeys([]);
      setLoadedNodes([]);
      getFolderHeaders(selectedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchClear]);

  // functions
  const fetchStates = async () => {
    setIsStatesLoading(true);

    const response = await getStateList();
    let formattedStatesData = [];
    let formattedStatesObj = {};

    if (response && response.success) {
      for (let i = 0; i < response.data.length; i++) {
        const state = response.data[i];
        formattedStatesData = [
          ...formattedStatesData,
          { value: state.id, label: state.name },
        ];
        formattedStatesObj = {
          ...formattedStatesObj,
          [state.id]: state.name,
        };
      }
      dispatch(setStates(formattedStatesData, formattedStatesObj));
    }
    setIsStatesLoading(false);
  };

  const getFolderHeaders = async (states) => {
    let state_ids = [];
    if (states.length > 0) {
      state_ids = states.map((val) => val.value);
    }
    setIsDataTreeLoading(true);
    let { success, data } = await getFolderData(state_ids);
    if (success && data && data.length > 0) {
      let resultObject = {};
      let ids = [];
      data.forEach((item) => {
        ids.push(item.id);
        if (!resultObject[item.id]) {
          resultObject[item.id] = item;
        }
      });

      const modifiedArr = formatData(data, true);

      if (
        modifiedArr[0] &&
        modifiedArr[0].children &&
        modifiedArr[0].children[0] &&
        modifiedArr[0].children[0].children[0]
      ) {
        dispatch(setSelectedTreeNode([modifiedArr[0].children[0].children[0]]));
      }
      setLoadedNodes([modifiedArr[0].id]);
      dispatch(setDataTree(modifiedArr));
      dispatch(setRegulationList({ list: resultObject, ids }));
    } else {
      dispatch(setDataTree([]));
    }
    setIsDataTreeLoading(false);
  };

  const getParentCitations = async (states, folderId, folders = null) => {
    let state_ids = [];
    if (states.length > 0) {
      state_ids = states.map((val) => val.value);
    }

    let { success, data } = await getParentCitationData(state_ids, folderId);
    let tree = dataTree;

    if (!tree && folders) {
      tree = folders;
    }

    if (success && data && Object.keys(data).length > 0) {
      let objArray = Object.keys(data).map((val) => {
        const children = data[val].map((child) => {
          return { ...child, folderId, type: "upload" };
        });

        return {
          folderId,
          citation: statesObj[val],
          children,
          loadable: false,
          id: `${statesObj[val]}-${uuidv1()}`,
          stateId: val,
          type: "state",
        };
      });

      const modifiedArr = formatData(objArray);

      dispatch(
        setDataTree(
          tree.map((header) => {
            if (header.id === folderId) {
              return {
                ...header,
                children: modifiedArr,
              };
            }
            return header;
          })
        )
      );
      setLoadedNodes([folderId, ...loadedNodes]);
    } else if (success && data && Object.keys(data).length === 0) {
      dispatch(
        setDataTree(
          tree.map((header) => {
            if (header.id === folderId) {
              return {
                ...header,
                children: [],
              };
            }
            return header;
          })
        )
      );
      setLoadedNodes([...loadedNodes, folderId]);
    }
    return true;
  };

  const formatData = (arr, headers = false) => {
    try {
      return arr.map((node) => {
        if (headers) {
          node.children = [];
          if (node.citation && Object.keys(node.citation).length > 0) {
            let objArray = Object.keys(node.citation).map((val) => {
              const children = node.citation[val].map((child) => {
                return { ...child, folderId: node.id, type: "upload" };
              });

              return {
                folderId: node.id,
                citation: statesObj[val],
                children,
                loadable: false,
                id: `${statesObj[val]}-${uuidv1()}`,
                stateId: val,
                type: "state",
              };
            });
            node.children = objArray;
          }

          node.citation = node.name;
          node.type = "folder";
        }
        if (node.children) {
          node.children = formatData(node.children);
        }

        if (!has(node, "isLeaf")) {
          if (
            (has(node, "loadable") && node.loadable) ||
            (has(node, "children") && size(node.children) > 0)
          ) {
            node.isLeaf = false;
          } else {
            node.isLeaf = true;
          }
        }

        if (node.citation || node.regulation) {
          node.title = (
            <p
              dangerouslySetInnerHTML={{
                __html: node.citation ?? node.regulation,
              }}
            />
          );
        }

        node.loading = false;
        return node;
      });
    } catch (error) {
      console.log(error);
      return arr;
    }
  };

  const onSelect = async (selectedKeys, e) => {
    if (e.node.upload_id) {
      dispatch(setSelectedTreeNode([e.node]));

      if (!searchSideBar && selectedFilter?.keyword) {
        dispatch(setSearchSideBar(true));
        dispatch(clearSelectedFilter());
        navigate(`/search-result/${selectedFilter.keyword}`);
      }

      try {
        if (e.nativeEvent.srcElement.parentElement.tagName === "DIV") {
          e.nativeEvent.srcElement.parentElement
            .querySelector(".ant-tree-switcher")
            .click();
        } else if (
          e.nativeEvent.srcElement.parentElement.parentElement.tagName === "DIV"
        ) {
          e.nativeEvent.srcElement.parentElement.parentElement
            .querySelector(".ant-tree-switcher")
            .click();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onLoadData = async (node) => {
    try {
      const { id, pos } = node;
      let keys = pos.split("-");
      if (keys.length === 2) {
        await getParentCitations(selectedState, id);
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const selectState = (e) => {
    dispatch(setIsLoadingRegTree(true));
    if (e.length === 0) {
      dispatch(setDataTree([]));
      dispatch(setIsLoadingRegTree(false));
    }
    dispatch(setSelectedTreeNode([]));
    setSelectedKeys([]);
    setLoadedNodes([]);
    dispatch(setSelectedState(e));
  };

  const onExpand = (arr) => {
    if (typeof arr[arr.length - 1] === "string") {
      setSelectedKeys(arr);
    } else {
      setSelectedKeys([arr[arr.length - 1]]);
    }
  };

  const toggleExpandNode = (key) => {
    if (typeof key === "string") {
      setSelectedKeys((prev) => {
        const outArr = [...prev];
        if (outArr.includes(key)) {
          return outArr.filter((e) => e !== key);
        } else {
          outArr.push(key);
          return outArr;
        }
      });
    } else {
      if (selectedKeys.includes(key)) {
        setSelectedKeys([]);
      } else {
        setSelectedKeys([key]);
      }
    }
  };

  const getSearchResultsCount = (data) => {
    switch (data.type) {
      case "folder":
        return searchResultsCounterData[data.id]?.total.toLocaleString() || "0";
      case "state":
        return (
          searchResultsCounterData[data.folderId]?.states[
            data.stateId
          ]?.total.toLocaleString() || "0"
        );
      case "upload":
        return (
          searchResultsCounterData[data.folderId]?.states[
            data.state_id
          ]?.uploads[data.upload_id]?.total.toLocaleString() || "0"
        );
      default:
        return "-";
    }
  };

  return (
    <StyleSideBar id="sidebar" className="sidebar sidebar-offcanvas">
      <div id="scrollable-content" className="m-0 scroll">
        <CheckMultiSelect
          key="example_id"
          label="State"
          disabled={isStatesLoading || searchSideBar || selectedFilter?.keyword}
          placeholder="Select State"
          options={states}
          value={stateArray}
          onChange={selectState}
          isSelectAll={true}
          menuPlacement={"bottom"}
        />

        <StyleTreeView>
          <Skeleton loading={isDataTreeLoading}>
            {dataTree && dataTree.length > 0 ? (
              <Tree
                showLine
                switcherIcon={<CaretDownFilled />}
                onSelect={onSelect}
                loadData={onLoadData}
                loadedKeys={loadedNodes}
                treeData={dataTree}
                fieldNames={{
                  title: "title",
                  key: "id",
                  children: "children",
                }}
                expandedKeys={selectedKeys}
                onExpand={onExpand}
                selectedKeys={selectedNodeKey}
                titleRender={(data) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center">
                      <p
                        className="mb-0"
                        key={data.id}
                        //trigger expand on tree title click
                        onClick={() => toggleExpandNode(data.id)}
                        dangerouslySetInnerHTML={{ __html: data.citation }}
                      />
                      {showSearchResultsCount && (
                        <span className="result-count">
                          {loadingSearchResultsCount ? (
                            '-'
                          ) : (
                            getSearchResultsCount(data)
                          )}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            ) : (
              <Alert key={"primary"} variant={"primary"}>
                {isEmpty(selectedState)
                  ? SearchLicenseHelper.EMPTY_LICENSE_DEFAULT_MESSAGE
                  : SearchLicenseHelper.NO_REGULATIONS_FOUND}
              </Alert>
            )}
          </Skeleton>
        </StyleTreeView>
      </div>
    </StyleSideBar>
  );
}

export default SideBar;
