import { ThemeProvider } from 'styled-components';
import theme from './components/styles/Theme.styled';
import GlobalStyles from './components/styles/Global';
import { AppRoutes } from './routes';
import { AuthContextProvider } from './containers/auth/AuthContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
