import styled from 'styled-components';

export const StyleHeader = styled.header`
.navbar{
  background-color: white;
  padding: 10px 0;
  box-shadow: 0 18px 35px -8px rgb(161 176 205 / 18%);
  min-height:85px;
}
 
  img {
    padding: 0;
  }
  .navbar-nav {
    a {
      font-size: 18px;
      color: white;
      font-weight: 400;
      margin: 0 22px;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
      &:hover {
        border-bottom: 2px solid;
        font-weight: 700;
      }
    }
    @media (max-width: 575.98px) {
      a {
        margin: 0;
        padding: 10px 0;
        font-size: 16px;
      }
    }
  }
  button {
    border: none;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      &:focus {
        box-shadow: none;
      }
    }
  }
`;
export const AppNav = styled.nav`
  a {
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
  }
  .user-action-dropdown{
    .dropdown-toggle{
      transition: 200ms cubic-bezier(0.39, 0.58, 0.57, 1);
      font-weight: 600;
      color: ${(props) => props.theme.variableColor.fontBlack};
      &:hover, &:focus{
        opacity:.75;
        text-decoration: none;
      }
      img{
        margin-left: .5rem;
        :hover{
          opacity:.75;
        }
      }
      &::after{
        display:none;
        text-decoration: none;
      }
    }
    .dropdown-menu[data-bs-popper]{
      box-shadow: 0 0px 35px -8px rgb(161 176 205 / 45%);
        right: 0;
        left: auto;
        top: 35px;
        border: none;
    }
}
`;

export const StyleSearch = styled.div`
  position: relative;
  img {
    position: absolute;
    right: 17px;
    top: 17px;
  }
  input {
    padding: 0.75rem 3rem 0.75rem 0.75rem;
    border-color: ${props => props.theme.variableColor.borderGray};
    border-radius: 5px;
    &::placeholder {
      color: ${props => props.theme.variableColor.borderGray};
      font-weidth: 700;
    }
  }
`;
