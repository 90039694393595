/* eslint-disable import/prefer-default-export */
import * as searchReducer from '../reducers/search';

export const setSearchFilter = data => dispatch => {
  dispatch(searchReducer.SET_SEARCH_FILTER(data));
};

export const setSelectedFilter = data => dispatch => {
  dispatch(searchReducer.SET_SELECTED_SEARCH_FILTER(data));
};

export const clearSelectedFilter = () => dispatch => {
  dispatch(searchReducer.CLEAR_SELECTED_SEARCH_FILTER());
};

export const setSelectedNodeId = data => dispatch => {
  dispatch(searchReducer.SET_SELECTED_SEARCH_NODE(data));
};

export const setSelectedState = data => dispatch => {
  dispatch(searchReducer.SET_SELECTED_STATE(data));
};

export const setSearchClearState = data => dispatch => {
  dispatch(searchReducer.SET_SEARCH_CLEAR_STATE(data));
};

export const setRegulationList = data => dispatch => {
  dispatch(searchReducer.SET_REGULATION_LIST(data));
};

export const setStates = (states, statesObj) => dispatch => {
  dispatch(searchReducer.SET_STATES({states, statesObj}))
} 

export const setSearchSideBar = data => dispatch => {
  dispatch(searchReducer.SET_SEARCH_SIDEBAR(data))
}

export const setIsLoadingRegTree = data => dispatch => {
  dispatch(searchReducer.SET_IS_LOADING_REG_TREE(data))
}

export const clearSearchReducer = () => dispatch => {
  dispatch(searchReducer.CLEAR_SEARCH_REDUCER())
}