const StateValueHelper = Object.freeze({
  STATES: [
    {
      value: 'Alabama',
      label: 'AL',
      key: 1
    },
    {
      value: 'Alaska',
      label: 'AK',
      key: 2
    },
    {
      value: 'Arizona',
      label: 'AZ',
      key: 4
    },
    {
      value: 'Arkansas',
      label: 'AR',
      key: 5
    },
    {
      value: 'California',
      label: 'CA',
      key: 6
    },
    {
      value: 'Colorado',
      label: 'CO',
      key: 8
    },
    {
      value: 'Connecticut',
      label: 'CT',
      key: 9
    },
    {
      value: 'Delaware',
      label: 'DE',
      key: 10
    },
    {
      value: 'District of Columbia',
      label: 'DC',
      key: 11
    },
    {
      value: 'Florida',
      label: 'FL',
      key: 12
    },
    {
      value: 'Georgia',
      label: 'GA',
      key: 13
    },
    {
      value: 'Hawaii',
      label: 'HI',
      key: 15
    },
    {
      value: 'Idaho',
      label: 'ID',
      key: 16
    },
    {
      value: 'Illinois',
      label: 'IL',
      key: 17
    },
    {
      value: 'Indiana',
      label: 'IN',
      key: 18
    },
    {
      value: 'Iowa',
      label: 'IA',
      key: 19
    },
    {
      value: 'Kansas',
      label: 'KS',
      key: 20
    },
    {
      value: 'Kentucky',
      label: 'KY',
      key: 21
    },
    {
      value: 'Louisiana',
      label: 'LA',
      key: 22
    },
    {
      value: 'Maine',
      label: 'ME',
      key: 23
    },
    {
      value: 'Maryland',
      label: 'MD',
      key: 24
    },
    {
      value: 'Massachusetts',
      label: 'MA',
      key: 25
    },
    {
      value: 'Michigan',
      label: 'MI',
      key: 26
    },
    {
      value: 'Minnesota',
      label: 'MN',
      key: 27
    },
    {
      value: 'Mississippi',
      label: 'MS',
      key: 28
    },
    {
      value: 'Missouri',
      label: 'MO',
      key: 29
    },
    {
      value: 'Montana',
      label: 'MT',
      key: 30
    },
    {
      value: 'Nebraska',
      label: 'NE',
      key: 31
    },
    {
      value: 'Nevada',
      label: 'NV',
      key: 32
    },
    {
      value: 'New Hampshire',
      label: 'NH',
      key: 33
    },
    {
      value: 'New Jersey',
      label: 'NJ',
      key: 34
    },
    {
      value: 'New Mexico',
      label: 'NM',
      key: 35
    },
    {
      value: 'New York',
      label: 'NY',
      key: 36
    },
    {
      value: 'North Carolina',
      label: 'NC',
      key: 37
    },
    {
      value: 'North Dakota',
      label: 'ND',
      key: 38
    },
    {
      value: 'Ohio',
      label: 'OH',
      key: 39
    },
    {
      value: 'Oklahoma',
      label: 'OK',
      key: 40
    },
    {
      value: 'Oregon',
      label: 'OR',
      key: 41
    },
    {
      value: 'Pennsylvania',
      label: 'PA',
      key: 42
    },
    {
      value: 'Puerto Rico',
      label: 'PR',
      key: 72
    },
    {
      value: 'Rhode Island',
      label: 'RI',
      key: 43
    },
    {
      value: 'South Carolina',
      label: 'SC',
      key: 45
    },
    {
      value: 'South Dakota',
      label: 'SD',
      key: 46
    },
    {
      value: 'Tennessee',
      label: 'TN',
      key: 47
    },
    {
      value: 'Texas',
      label: 'TX',
      key: 48
    },
    {
      value: 'Utah',
      label: 'UT',
      key: 49
    },
    {
      value: 'Vermont',
      label: 'VT',
      key: 50
    },
    {
      value: 'Virginia',
      label: 'VA',
      key: 51
    },
    {
      value: 'Virgin Islands',
      label: 'VI',
      key: 78
    },
    {
      value: 'Washington',
      label: 'WA',
      key: 53
    },
    {
      value: 'West Virginia',
      label: 'WV',
      key: 54
    },
    {
      value: 'Wisconsin',
      label: 'WI',
      key: 55
    },
    {
      value: 'Wyoming',
      label: 'WY',
      key: 56
    }
  ],
  STATES_NEW: [
    {
      label: 'AK',
      value: 10
    },
    {
      label: 'AL',
      value: 9
    },
    {
      label: 'AR',
      value: 12
    },
    {
      label: 'AZ',
      value: 11
    },
    {
      label: 'CA',
      value: 7
    },
    {
      label: 'CO',
      value: 1
    },
    {
      label: 'CT',
      value: 13
    },
    {
      label: 'DC',
      value: 55
    },
    {
      label: 'DE',
      value: 14
    },
    {
      label: 'FL',
      value: 15
    },
    {
      label: 'GA',
      value: 16
    },
    {
      label: 'HI',
      value: 17
    },
    {
      label: 'IA',
      value: 21
    },
    {
      label: 'ID',
      value: 18
    },
    {
      label: 'IL',
      value: 19
    },
    {
      label: 'IN',
      value: 20
    },
    {
      label: 'KS',
      value: 22
    },
    {
      label: 'KY',
      value: 23
    },
    {
      label: 'LA',
      value: 24
    },
    {
      label: 'MA',
      value: 27
    },
    {
      label: 'MD',
      value: 26
    },
    {
      label: 'ME',
      value: 25
    },
    {
      label: 'MI',
      value: 28
    },
    {
      label: 'MN',
      value: 29
    },
    {
      label: 'MO',
      value: 31
    },
    {
      label: 'MS',
      value: 30
    },
    {
      label: 'MT',
      value: 32
    },
    {
      label: 'NC',
      value: 38
    },
    {
      label: 'ND',
      value: 39
    },
    {
      label: 'NE',
      value: 33
    },
    {
      label: 'NH',
      value: 34
    },
    {
      label: 'NJ',
      value: 35
    },
    {
      label: 'NM',
      value: 36
    },
    {
      label: 'NV',
      value: 6
    },
    {
      label: 'NY',
      value: 37
    },
    {
      label: 'OH',
      value: 40
    },
    {
      label: 'OK',
      value: 41
    },
    {
      label: 'OR',
      value: 8
    },
    {
      label: 'PA',
      value: 42
    },
    {
      label: 'RI',
      value: 43
    },
    {
      label: 'SC',
      value: 44
    },
    {
      label: 'SD',
      value: 45
    },
    {
      label: 'TN',
      value: 46
    },
    {
      label: 'TX',
      value: 47
    },
    {
      label: 'UT',
      value: 48
    },
    {
      label: 'VA',
      value: 50
    },
    {
      label: 'VT',
      value: 49
    },
    {
      label: 'WA',
      value: 51
    },
    {
      label: 'WI',
      value: 53
    },
    {
      label: 'WV',
      value: 52
    },
    {
      label: 'WY',
      value: 54
    }
  ]
});

export default StateValueHelper;
