import React, { useState } from "react";
import { useParams } from "react-router";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";

import LoginHelper from "../../helpers/features/LoginHelper";
import AppInput from "../common/input/AppInput";
import AppButton from "../styles/ButtonLink.styled";
import { useAuth } from "../../containers/auth/AuthContext";

function ResetPasswordForm() {
  const auth = useAuth();
  const params = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <center className="mb-4">
        <h5 className="mb-3">Set Password</h5>
        <p>Please enter a 8 or more character password that contain at least one uppercase letter, one lowercase letter, one symbol and one number.</p>
      </center>
      <Formik
        enableReinitialize
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={async (values) => {
          auth.handleResetPassword({ token: params?.token || "", ...values });
        }}
        validationSchema={yup.object().shape({
          password: yup.string().required("Please enter a password"),
          confirmPassword: yup
            .string()
            .required("Please re-enter your password")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
        })}
        validate={(values) => {
          const { password } = values;
          const errors = {};

          if (password) {
            const upperCaseRegex = /(?=.*[A-Z])/g;
            const lowerCaseRegex = /(?=.*[a-z])/g;
            const numericsRegex = /(?=.*\d)/g;
            const specialCharRegex = /(?=.*[^0-9a-zA-Z\s])/g;
            const whiteSpaceRegex = /(?=.*\s)/g;

            if (password.length < 8)
              errors.password = "Please enter a valid Password";

            if (!upperCaseRegex.test(password))
              errors.password = "Please enter a valid Password";

            if (!lowerCaseRegex.test(password))
              errors.password = "Please enter a valid Password";

            if (!numericsRegex.test(password))
              errors.password = "Please enter a valid Password";

            if (!specialCharRegex.test(password))
              errors.password = "Please enter a valid Password";

            if (whiteSpaceRegex.test(password))
              errors.password = "Please enter a valid Password";
          }

          return errors;
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, errors, isValid }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="position-relative mb-3">

                <AppInput
                  name="password"
                  id="password"
                  label="New Password"
                  requied="*"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  errorText={errors?.password || ""}
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  disabled={auth.isResettingPassword}
                />
                <button
                  className="rf-password-view-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
              <div className="position-relative mb-3">
                <AppInput
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                  requied="*"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  errorText={errors?.confirmPassword || ""}
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  disabled={auth.isResettingPassword}
                />
                <button
                  className="rf-password-view-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
              </div>
              <div className="d-grid">
                <AppButton
                  className="mt-2"
                  type="submit"
                  disabled={auth.isResettingPassword || !isValid}
                >
                  Set Password
                </AppButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ResetPasswordForm;
