import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDetails, updateUserDetails } from '../../apis/userApi';
import store from '../../store/store';
import * as commonActions from '../../store/actions/common';

const MyProfileContext = createContext();

function MyProfileContextProvider({ children }) {
  // user details
  const [userDetails, setuserDetails] = useState([]);
  const [message, setMessage] = useState({
    type: null,
    message: null,
  });

  const fetchUserDetails = async () => {
    try {
      const response = await getUserDetails();
      // setuserDetails(response.data);
    } catch (e) {
      console.error('Unable to authorize user from backend', e);
    }
  };

  const handleUpdateUserDetails = async (body, id) => {
    try {
      const data = body;
      const response = await updateUserDetails(data, id);
      if (response.success && response.data) {
        store.dispatch(
          commonActions.showSuccessAlert({
            type: 'success',
            delay: 3000,
            message: response.message,
            body: 'user update success !',
          }),
        );
      } else {
        setMessage({
          type: 'error',
          message: response.message,
        });
        setTimeout(() => {
          setMessage({
            type: null,
            message: null,
          });
        }, 3000);
      }
    } catch (e) {
      console.log(e);
      setMessage({
        type: 'error',
        message: e,
      });
      setTimeout(() => {
        setMessage({
          type: null,
          message: null,
        });
      }, 3000);
      console.error('Unable to authorize user from backend', e);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                Return values                               */
  /* -------------------------------------------------------------------------- */
  const value = useMemo(
    () => ({
      userDetails,
      handleUpdateUserDetails,
      message,
    }),
    [userDetails, handleUpdateUserDetails, message],
  );

  return <MyProfileContext.Provider value={value}>{children}</MyProfileContext.Provider>;
}

function useMyProfile() {
  return useContext(MyProfileContext);
}

MyProfileContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MyProfileContextProvider, useMyProfile };
