import { createSlice } from '@reduxjs/toolkit';
import reducerTypes from '../reducerTypes';
import { SEARCH_DEFAULT_FILTER } from '../../helpers/features/SearchHelper';

const initialState = {
  searchFilter: SEARCH_DEFAULT_FILTER,
  selectedFilter: {},
  selectedNode: null,
  selectedState: [],
  isSearchClear: false,
  regulationList: {},
  regulationIds: [],
  states: [],
  statesObj: {},
  searchSideBar: false,
  isLoadingRegTree: false,
};

export const searchSlice = createSlice({
  name: reducerTypes.search,
  initialState,
  reducers: {
    SET_SEARCH_FILTER: (state, data) => {
      state.searchFilter = data.payload;
    },
    SET_SELECTED_SEARCH_FILTER: (state, data) => {
      state.selectedFilter = data.payload;
    },
    CLEAR_SELECTED_SEARCH_FILTER: (state, data) => {
      state.selectedFilter = {};
      state.selectedNode = null;
    },
    SET_SELECTED_SEARCH_NODE: (state, data) => {
      state.selectedNode = data.payload;
    },
    SET_SELECTED_STATE: (state, data) => {
      state.selectedState = data.payload;
    },
    SET_SEARCH_CLEAR_STATE: (state, data) => {
      state.isSearchClear = data.payload;
    },
    SET_REGULATION_LIST: (state, data) => {
      state.regulationList = data.payload.list;
      state.regulationIds = data.payload.ids;
    },
    SET_STATES: (state, data) => {
      state.states = data.payload.states;
      state.statesObj = data.payload.statesObj;
    },
    SET_SEARCH_SIDEBAR: (state, data) => {
      state.searchSideBar = data.payload;
    },
    SET_IS_LOADING_REG_TREE: (state, data) => {
      state.isLoadingRegTree = data.payload
    },
    CLEAR_SEARCH_REDUCER: (state) => initialState
  }
});

export const {
  SET_SEARCH_FILTER,
  SET_SELECTED_SEARCH_FILTER,
  CLEAR_SELECTED_SEARCH_FILTER,
  SET_SELECTED_SEARCH_NODE,
  SET_SELECTED_STATE,
  SET_SEARCH_CLEAR_STATE,
  SET_REGULATION_LIST,
  SET_STATES,
  SET_SEARCH_SIDEBAR,
  SET_IS_LOADING_REG_TREE,
  CLEAR_SEARCH_REDUCER
} = searchSlice.actions;

export default searchSlice.reducer;
