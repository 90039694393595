import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './reducers/auth';
import commonReducer from './reducers/common';
import searchReducer from './reducers/search';
import profileReducer from './reducers/profile';
import sidebarReducer from './reducers/sidebar';
import reducerTypes from './reducerTypes';

const rootReducer = {};
rootReducer[reducerTypes.auth] = authReducer;
rootReducer[reducerTypes.common] = commonReducer;
rootReducer[reducerTypes.search] = searchReducer;
rootReducer[reducerTypes.profile] = profileReducer;
rootReducer[reducerTypes.sidebar] = sidebarReducer;

export default combineReducers(rootReducer);
