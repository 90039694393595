import styled from "styled-components";

export const StyleSideBar = styled.div`
    top:85px;
    box-shadow: 0 19px 43px rgb(161 176 205 / 26%);
    background: #fff;
    padding: 20px;
    width: 100%;
    bottom: 0;
    z-index: 2;
    position: fixed;
    left: 0;
    transition: width 0.25s ease, margin 0.25s ease;
    max-height: calc(100vh - 85px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 575.98px) {
      top:145px;
    }
    @media (min-width: 992px) {
      width: 23%;
    }
    @media (min-width: 1700px) {
      width: 400px;
    }
    @media screen and (max-width: 991px){
        position: fixed;
        max-height: calc(100vh - 60px);
        top: 60px;
        bottom: 0;
        left: -130%;
        transition: all 0.25s ease-out;

        &.active {
          left: 0;
        }
      } 
`;
