import PropTypes from 'prop-types';
import { Container, Row, Col, Image } from 'react-bootstrap';
import StyleLogin from '../../../containers/auth/Login.styled';
import Logo from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';

function Authlayout(props) {
  const { children } = props;
  return (
    <StyleLogin className="w-100 px-4 py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={4} lg={4} xl={4}>
            <center>
              <Image src={Logo} alt="Cannabis Regulation Library" />
            </center>
            <div className="login-wrap">{children}</div>
            <nav class="justify-content-center navbar fixed-bottom navbar-light bg-light">
              <a href="http://www.simplifya.com/terms-of-service/" target='_blank' className="text-black link-type me-2">
                Terms of Use
              </a>{" "}
              |{" "}
              <a href="https://www.simplifya.com/privacy-policy/" target='_blank'  className="text-black link-type ms-2">
                Privacy Policy
              </a>
            </nav>
          </Col>
        </Row>
      </Container>
    </StyleLogin>
  );
}

Authlayout.propTypes = {
  children: PropTypes.node.isRequired,
  mainHeader: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired
};

export default Authlayout;
