import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import StyleForm from '../input/Form.styled';

function ErrorNotification({ errorMessage }) {
  return (
    <StyleForm>
      <Form.Text className="error-text">{errorMessage}</Form.Text>
    </StyleForm>
  );
}

ErrorNotification.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorNotification;
