import { createSlice } from '@reduxjs/toolkit';
import reducerTypes from '../reducerTypes';
import MyProfileHelper from '../../helpers/features/MyProfileHelper';

const initialState = {
    isSidePanelOpen: false,
    activeTab: MyProfileHelper.PROFILE_SIDEPANEL_TABS.GENERAL.KEY
};

export const profileSlice = createSlice({
    name: reducerTypes.profile,
    initialState,
    reducers: {
        OPEN_PROFILE_SIDE_PANEL: (state, data) => {
            state.isSidePanelOpen = true;
        },
        CLOSE_PROFILE_SIDE_PANEL: (state, data) => {
            state.isSidePanelOpen = false;
        },
        SET_CURRENT_TAB: (state, data) => {
            state.activeTab = data.payload;
        }
    },
});

export const { OPEN_PROFILE_SIDE_PANEL, CLOSE_PROFILE_SIDE_PANEL, SET_CURRENT_TAB } = profileSlice.actions;

export default profileSlice.reducer;
