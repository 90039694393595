import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import * as CONFIG from '../config/config';
import commonHelper from '../helpers/common';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['SEARCH'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const options = {
  reducer: persistedReducer,
  middleware: [thunk],
};

if (CONFIG.ENV === commonHelper.ENV.PRODUCTION) {
  options.devTools = false;
}

const store = configureStore(options);

export const persistor = persistStore(store);

export default store;
