import { get, patch, put } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';
import replaceParameters from '../helpers/parameterHelper';

export const getFolderData = async (stateIds) => {
  try {
    const config = {};
    const params = { stateIds }
    const { data } = await get(apiRoutes.REGULATIONS.FOLDER, { params, config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error;
  }
};

export const getParentCitationData = async (stateIds, folderId, queryParamString=null) => {
  try {
    let url = replaceParameters(apiRoutes.REGULATIONS.FOLDER_PARENT_CITATIONS, { folderId });
    if(queryParamString) {
      url = `${url}?${queryParamString}`;
    }
    const config = {};
    const params = { stateIds }
    const { data } = await get(url, { params, config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error;
  }
};

export const getRegTreeData = async (id, isNotSearch=false, queryParamString=null) => {
  try {
    let url = replaceParameters(isNotSearch ? apiRoutes.REGULATIONS.REGULATIONS_CTREE : apiRoutes.REGULATIONS.REGULATIONS_TREE, { id });
    if(queryParamString) {
      url = `${url}?${queryParamString}`;
    }
    const config = {};
    const { data } = await get(url, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error;
  }
};

export const getDefinitionData = async (definition_id) => {
  try {
    const config = {};
    const url = replaceParameters(apiRoutes.REGULATIONS.DEFINITION, { definition_id });
    const { data } = await get(url, { config });
    return data.data ?? null
  } catch (error) {
    console.error(`error ${error}`);
    return null;
  }
};

export const updateUserDetails = async (body, id) => {
  try {
    const url = replaceParameters(apiRoutes.USER.UPDATE_USER_PROFILE_DETAILS, { id });
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const { data } = await patch(url, body, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const getStateList = async () => {
  try {
    const { data } = await get(apiRoutes.REGULATIONS.GET_STATES);
    return data
  } catch (error) {
    console.error(`get-state-list ${error}`)
    return error
  }
}

export const updateRegulationData = async (id, body) => {
  try {
    const url = replaceParameters(apiRoutes.REGULATIONS.REGULATION_UPDATE, { id });
    const { data } = await put(url, body);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};