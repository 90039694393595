import styled from 'styled-components';

export const StyleSearch = styled.div`
position: relative;
    .form-control {
        border-radius: 30px;
        width: 400px;
        padding: 0.375rem 3rem 0.375rem 1rem;
        border-color:  ${props => props.theme.variableColor.borderGray};
        min-height: 50px; 
        box-shadow: 0 3px 10px rgb(161 176 205 / 16%);
        &::placeholder{
            color: #969696;
        }
    }
    .clear-btn{
        position: absolute;
        right: 55px;
        transform: translateY(50%);
        padding: 8px;
        border-radius: 50%;
        line-height: 0;
        &:hover{
            img{
                opacity: 1;
            }
        }
        img{
            width: 10px;
            opacity: .4;
        }
    }
    .search-btn{
        position: absolute;
        right: 8px;
        padding: 5px;
    }
  @media (max-width: 575.98px) {
    .form-control {
        width: 100%;
    }
    .search-btn{
        right: 0;
    }
}
`;

