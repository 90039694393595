import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet">

    *{
        box-sizing: border-box;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    body{
        background: #F5F7F9;
        font-family: 'Poppins', sans-serif;
        color:  ${(props) => props.theme.variableColor.fontBlack};
        font-size: 14px;
    }
    .link-type, a {
        color:  ${(props) => props.theme.variableColor.primaryYellow};
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        &:hover {
            color:  ${(props) => props.theme.variableColor.primaryYellow};
            opacity: 0.8;
        }
    }
    .text-black {
        color:  ${(props) => props.theme.variableColor.fontBlack};
    }
    .form-check-label{
        cursor: pointer;
    }
    .color-red, .red{
        color: red;
    }
    .alert{
        text-align: center;
    }
    .default{
        color: ${(props) => props.theme.variableColor.grayLight};
    }
    .selected{
        color: ${(props) => props.theme.variableColor.primaryBlue};
    }
    .btn-md{
        font-size: 0.875rem;
        padding: 0.625rem 1.25rem !important;
    }
    .offcanvas-title.h5{
        font-size: 1.5rem;
        font-weight: 700;
    }
    .offcanvas-header .btn-close{
            background-color: ${(props) =>
              props.theme.variableColor.borderGray};
            height: 30px;
            width: 30px;
            border-radius: 50%; 
        }
    .outline-btn{
        background: transparent;
        padding: 15px 30px;
        font-size: 15px;
        font-weight: 600;
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme.variableColor.primaryYellow};
        color:  ${(props) => props.theme.variableColor.primaryYellow};
        transition: 200ms cubic-bezier(0.39, 0.58, 0.57, 1);
        &:hover{
            color:  ${(props) => props.theme.variableColor.darkYellow};
            border: 1px solid #ffbf0078;
        }
      }
    .btn-transparent{
        background: transparent;
        color:  ${(props) => props.theme.variableColor.grayBlue};
        border:none;
        font-size: 0.875rem;
        &:hover,  &:focus, &:active:focus{
            background: transparent;
            color:  ${(props) => props.theme.variableColor.grayBlue};
            opacity: 0.8;
            box-shadow:none;
            outline:none;
        }
    }
    .more-action{
        display: inline-flex;
    vertical-align: bottom;
    margin-left: 5px;
    }
    .btn-group{
        margin-left: 8px;
        .icon-btn{
            transition: .2s cubic-bezier(.39,.58,.57,1);
            // &:hover{
            //     transform:translateY(-5px);
            // }
        }
        &.bulk-edit{
            margin-bottom: 10px;
            button{
            width: 40px;
            height: 40px;
            border: 1px solid ${(props) =>
              props.theme.variableColor.primaryBlue};
            padding: 5px;
            border-radius: 10px; 
            img{
                
                opacity: 1;
                height: 22px;
            }
            &:hover{
                border: 1px solid  ${(props) =>
                  props.theme.variableColor.primaryBlue};
                    opacity: .8;
                }
            }
        }
    }
    .icon-btn{
        background: transparent;
        color:  ${(props) => props.theme.variableColor.primaryBlue};
        border:none;
        line-height: normal;
    }
    .round-bg.link-btn{
        background: #EFF6FF;
        border-radius: 50%;
        height: 38px;
        width: 38px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border: none;
        &:hover{
            background: white;
        }
    }
    .rf-password-view-btn {
        position: absolute;
        top: 36px;
        right: 0;
        transform: translate(0%, -50%);
        background: transparent;
        border: none;
        font-weight: 600;
        color: #ffbf00;
    }
    .rf-phone-input{
        .form-control{
          padding-left: 2.813rem !important;
          width: 100% !important;
        }
        .react-tel-input .flag-dropdown{
            border: none;
            background-color: transparent;
        }
        .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
            background: transparent !important;
          }
        .react-tel-input .selected-flag{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
            padding: 0;
            background: transparent !important;
        }
      }
    .light-outline-btn{
        background:transparent !important;
        color: ${(props) => props.theme.variableColor.grayLight}  !important;
        border: 1px solid ${(props) =>
          props.theme.variableColor.borderGray} !important;
        &:hover{
            opacity: 1 !important;
            color: ${(props) => props.theme.variableColor.grayBlue} !important;
            background:transparent !important;
            border: 1px solid ${(props) =>
              props.theme.variableColor.grayBlue} !important;
        }
    }
    .ellipsis-menu {
        &.disabled{
            cursor: not-allowed !important;
        }
        button{
          padding:0;
          border:none;
          &:hover, &:active, &:focus{ opacity: .75;}
          &::after{display: none;}
        }
        }
        .dropdown-menu{
            padding: .5rem;
        }
        .dropdown-item:focus, .dropdown-item:active,  .dropdown-item:hover{
            border-radius: 5px;
            background: ${(props) => props.theme.variableColor.borderGray};
            color: ${(props) => props.theme.variableColor.fontBlack};
        }
    .navbar-brand{padding:0;}
    .app-link{
        color: ${(props) => props.theme.variableColor.primaryYellow};
        text-decoration: none;
        &:hover{
        color:  ${(props) => props.theme.variableColor.primaryYellow};
        opacity: .8;
        }
    }
    input:-webkit-autofill, .form-control:focus:-webkit-autofill {
        -webkit-box-shadow: 200px 200px 100px white inset !important;
        box-shadow: 200px 200px 100px white inset !important;
      } {
        -webkit-box-shadow: 200px 200px 100px white inset;
        box-shadow: 200px 200px 100px white inset;
      }
    .text-muted{
        font-size: 14px;
        font-weight: 400;
        color: black !important;
    }
    .cc-info-input{
        padding-bottom: 0.625rem;
        border-bottom: 1px solid ${(props) =>
          props.theme.variableColor.borderGray};
      }
    .width-auto{width: auto;}
    .full-width{width: 100%;}

    /* ====================  Font - Styles  ====================  */
    .font-12{font-size:12px;}
    .font-14{font-size:14px;}
    .font-16{font-size:16px;}
    .font-18{font-size:18px;}
    .font-20{font-size:20;}
    .font-24{font-size:24;}

    /* ====================  Buttons - Styles  ====================  */
    .link-btn, .link-btn:hover{
        background: transparent;
        border:none;
        padding: 0;
        color:  ${(props) => props.theme.variableColor.primaryBlue}; 
        &:hover{
            text-decoration: underline;
        }
        &:focus, &:active{
            box-shadow: none;
            background: transparent;
            text-decoration: underline;
            color:  ${(props) => props.theme.variableColor.primaryBlue}; 
        }
    }
    /* ====================  Common - Styles  ====================  */
    .ac-item:last-child .accordion{
        border-bottom: none;
     }
     .main-wrap {
        min-height: calc(100vh - 60px);
        flex-direction: column;
        padding-top: 85px;
        padding-left: 0;
        padding-right: 0;
        background: #f3f5f9;
        @media (max-width: 575.98px) {
            padding-top: 85px;
        }
    }
    /* ====================  Scroll bar - Styles  ====================  */

    ::-webkit-scrollbar, li::-webkit-scrollbar, nav::-webkit-scrollbar {
        height: 4px;
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar-thumb {
        background: #a0a0a0;
    }
   ::-webkit-scrollbar-track {
        background: transparent;
    }
    
    /* ====================  Badge - Styles  ====================  */
    .badge{
        height: max-content;
        border-radius: 30px;
        min-width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* ====================  Search Result - Styles  ====================  */
    .left-column.card {
        padding: 0;
        .all-select{
            padding:5px 30px 5px 31px;
            .form-check-label{font-weight: 700;}
        }
    }
    .left-column, .details-wrap{
        -webkit-transition: width 0.25s ease,margin 0.25s ease;
        transition: width 0.25s ease,margin 0.25s ease;
        height: calc(100vh - 145px);
        overflow: auto;
        .alert-primary{
            background: #E7F2FE;
            border-color: #E7F2FE;
            border-radius: 10px;
            color:  ${(props) => props.theme.variableColor.primaryBlue};
            font-size: 16px;
            font-weight: 500;
            padding: 12px 20px;
        }
        .ant-collapse{
            background: none;
            border: none;
        }
        .ant-collapse-content>.ant-collapse-content-box{
            padding: 0;
        }
        .ant-collapse-item{
            border-bottom: none;
            border-radius: 5px;
            .ant-collapse-header-text{
                p{
                    font-weight: 700;
                }
            }
            &:hover{
                background: #ffefc7;
                border-radius: 5px;
            }
            &.ant-collapse-item-active{
                .ant-collapse-header{
                    background: #FDC126;
                    margin-bottom: 10px;
                    border-radius: 5px;
                }
                &:hover{
                    background: none;
                }
                .ant-spin-container{
                    padding: 0 0 0 30px;
                }
                li.ant-list-item{
                    padding: 8px;
                    border-radius: 5px;
                    &:hover{
                        background: #ffefc7;
                    }
                }
                .ant-list-item .form-check{
                    min-height: 0;
                    margin-bottom: 0;
                }
            }
            .ant-collapse-header{
                padding: 5px 10px 5px 10px;
                .ant-collapse-arrow{
                    top: 23%;
                }
            }
        }
        .ant-collapse-content{
            border-top: none;
            .ant-collapse-content-box{
                padding-top: 0;
                padding-bottom: 0;
                .ant-list-item{
                    padding: 0;
                    border-bottom: none;
                    display: block;
                }
            }
        }
    }
    .offcanvas {
        width: 50% !important;
        padding:1rem;
    }
    .ant-typography mark{
        background: #FAF10D;
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
        background: transparent;
    }
    .ant-tree-switcher-icon {
        color: black !important;
        font-size: 12px !important;
        vertical-align: middle !important;
    }
    h5.ant-typography{
        font-size: 20px;
        margin-bottom: 0;
    }
    .edit-button {
        cursor: pointer;
        color: ${(props) => props.theme.variableColor.primaryBlue} !important;
        margin-left: 3px;
    }
   
    @media only screen and (max-width: 767px) {
        .offcanvas {
          width: 100% !important;
        }
    }
    @media (min-width: 992px) {
        .search-sidebar-open{
            .dash {
                width: calc(100% - 45%) !important;
            }
        }
    }
    @media (min-width: 1700px) {
        .search-sidebar-open{
            .dash {
                width: calc(100% - 800px)!important;
            }
        }
    }
`;

export default GlobalStyles;
