import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import routes from '../../helpers/routes';

export const AuthorizedWrapper = () => {
  const auth = useAuth();

  if (auth.isBackendAuthorized) {
    return <Outlet />;
  }

  return <Navigate to={routes.UN_AUTHENTICATED.LOGIN.PATH} />;
};

export const UnauthorizedWrapper = () => {
  const auth = useAuth();

  if (!auth.isBackendAuthorized) {
    return <Outlet />;
  }

  return <Navigate to={routes.AUTHENTICATED.ROOT.PATH} />;
};
