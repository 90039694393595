import React from "react";
import { Container } from 'react-bootstrap';
import { StyleDashboard } from '../home/Home.styled';

function MainWrap({children, customClass}) { 
  return (
    <StyleDashboard>
        <Container fluid className={ `main-wrap ${customClass}` }>
          {children}
        </Container>
    </StyleDashboard>
  );
}

export default MainWrap;
