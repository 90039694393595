/* eslint-disable arrow-body-style */
import { jwtDecode } from "jwt-decode";

import { LOGS_ENABLED } from '../config/config';
import StateHelper from '../helpers/stateHelper';

// payment method svgs
import VisaCard from '../assets/images/visa-card.svg'
import AmexCard from '../assets/images/amex-card.svg'
import DiscoverCard from '../assets/images/discover-card.svg'
import MasterCard from '../assets/images/master-card.svg'
import JcbCard from '../assets/images/jcb-card.svg'

/* -------------------------------------------------------------------------- */
/*                                   String                                   */
/* -------------------------------------------------------------------------- */
/**
 * This function will return a given string after removing trailing slash
 * @param {String} value string for modify
 * @returns modified string
 */
const removeTrailingSlash = (value = '') => value.replace(/\/$/, '');

const replaceStrParameters = (value, parameters) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const param of Object.keys(parameters)) {
    value = value.replace('{{'.concat(param).concat('}}'), parameters[param]);
  }

  return value;
};

/* -------------------------------------------------------------------------- */
/*                                    Array                                   */
/* -------------------------------------------------------------------------- */
/**
 * This function will return true, when the given index is the last
 * @param {number} index current element array index
 * @param {number} length array length
 * @returns {boolean}
 */
const isLastElementInArray = (index = 0, length = 0) => length - 1 === index;

/**
 * This function will return new array after removing given element.
 * This will consider content of the given item to match correct element.
 * @param {Array} arr Array to remove element
 * @param {any} itemToRemove The item which needs to remove from the array
 * @returns {Array}
 */
const removeElementFromArrayConsideringData = (arr, itemToRemove) =>
  arr.filter(i => i !== itemToRemove);

/**
 * This function will return new array after adding given element.
 * @param {Array} arr Array to push new element
 * @param {*} itemToPush The item which needs to push to the array
 * @returns {Array}
 */
const pushToArray = (arr, itemToPush) => [...arr, itemToPush];

/* -------------------------------------------------------------------------- */
/*                                    Logs                                    */
/* -------------------------------------------------------------------------- */
/**
 * This function will return true, if REACT_APP_LOGS_ENABLED is true
 * @returns {Boolean}
 */
const isLogsEnabled = () => LOGS_ENABLED === 'true' || LOGS_ENABLED === true;

const filterStateName = stateAbbName => {
  const state = StateHelper.STATES.filter(item => {
    return item.label === stateAbbName;
  });
  return state[0].value || null;
};

/**
 * 
 * @param {*} yearsForward 
 * @returns array of years
 */
const generateYearOptions = (yearsForward) => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let i = currentYear; i <= currentYear + yearsForward; i++) {
    years.push({ value: i, label: i.toString() })
  }

  return years
}

const returnCardImage = (brand) => {
  switch (brand) {
    case 'discover':
      return DiscoverCard
    case 'visa':
      return VisaCard
    case 'amex':
      return AmexCard
    case 'mastercard':
      return MasterCard
    case 'jcb':
      return JcbCard
    default:
      break
  }
}

const decodeToken = (token) => {
  return jwtDecode(token);
}

const exportHtmlToDoc = (input, documentName) => {
  let html = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  var body1 = `${input}`;
  var footer = "</body></html>";
  html = html + body1 + footer;

  // link URL
  var url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Creates the download link element dynamically
  var downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  // Link to the file
  downloadLink.href = url;

  // Setting up file name
  downloadLink.download = `${documentName}.doc`;

  // Triggering the function
  downloadLink.click();

  // Remove the <a> tag after download starts.
  document.body.removeChild(downloadLink);
}

export {
  removeTrailingSlash,
  replaceStrParameters,
  isLastElementInArray,
  isLogsEnabled,
  removeElementFromArrayConsideringData,
  pushToArray,
  filterStateName,
  generateYearOptions,
  returnCardImage, 
  decodeToken,
  exportHtmlToDoc,
};
