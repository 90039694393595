import { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Toast from 'react-bootstrap/Toast';
import StyleForm from '../../input/Form.styled';

import commonHelper from '../../../../helpers/common';
import CloseIcon from '../../../../assets/images/check-circle.svg';

function ToastAlert(props) {
  const { type, delay, alert, hideAlert } = props;
  const [metaData, setMetaData] = useState(commonHelper.TOAST_ALERT_TYPES.SUCCESS);

  useEffect(() => {
    switch (type) {
      case commonHelper.TOAST_ALERT_TYPES.SUCCESS.type:
        setMetaData(commonHelper.TOAST_ALERT_TYPES.SUCCESS);
        break;
      case commonHelper.TOAST_ALERT_TYPES.INFO.type:
        setMetaData(commonHelper.TOAST_ALERT_TYPES.INFO);
        break;
      case commonHelper.TOAST_ALERT_TYPES.WARNING.type:
        setMetaData(commonHelper.TOAST_ALERT_TYPES.WARNING);
        break;
      case commonHelper.TOAST_ALERT_TYPES.ERROR.type:
        setMetaData(commonHelper.TOAST_ALERT_TYPES.ERROR);
        break;
      default:
        setMetaData(commonHelper.TOAST_ALERT_TYPES.SUCCESS);
    }
  }, [type]);

  return (
    <StyleForm>
      <Toast
        className="d-flex align-items-center"
        bg={metaData.cssClass}
        show={alert.show}
        onClose={() => hideAlert(alert.id)}
        delay={delay}
        autohide
        key={alert.id}
      >
        {' '}
        <Image src={CloseIcon} />
        <div className="tost-wrp full-width">
          <Toast.Header>
            {/* <div className="icon">
              <i className={metaData.icon} data-test="component-toast-alert__icon" />
            </div> */}
            <strong className="me-auto" data-test="component-toast-alert__message">
              {alert.message}
            </strong>
          </Toast.Header>
          <Toast.Body className="Dark">{alert.body}</Toast.Body>
        </div>
      </Toast>
    </StyleForm>
  );
}

ToastAlert.propTypes = {
  alert: PropTypes.shape({
    id: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    message: PropTypes.node.isRequired,
    body: PropTypes.node,
  }).isRequired,
  hideAlert: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    commonHelper.TOAST_ALERT_TYPES.SUCCESS.type,
    commonHelper.TOAST_ALERT_TYPES.INFO.type,
    commonHelper.TOAST_ALERT_TYPES.WARNING.type,
    commonHelper.TOAST_ALERT_TYPES.ERROR.type,
  ]),
  delay: PropTypes.number,
};

ToastAlert.defaultProps = {
  type: commonHelper.TOAST_ALERT_TYPES.SUCCESS.type,
  delay: 3000,
};

export default ToastAlert;
