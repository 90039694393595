export default Object.freeze({
  MY_PROFILE_HEADER: 'My Profile',
  MY_PROFILE_SUBHEADER: 'Edit your profile',
  MY_PROFILE_BTN_TEXT: 'Save',
  MY_PROFILE_FORM_EMAIL_LABLE: 'Email',
  MY_PROFILE_FORM_FIRST_NAME_LABLE: 'First Name',
  MY_PROFILE_FORM_LAST_NAME_LABLE: 'Last Name',
  MY_PROFILE_FORM_FIRST_NAME_PLACEHOLDER: 'Enter your first name',
  MY_PROFILE_FORM_LAST_NAME_PLACEHOLDER: 'Enter your last name',
  MY_PROFILE_FORM_EMAIL_PLACEHOLDER: 'Enter your email',
  MY_PROFILE_FORM_EMAIL_HELP_TEXT: 'Need to change your email? Contact',
  MY_PROFILE_FORM_EMAIL_HELP_TEXT_LINK: 'support@simplifya.com',
  MY_PROFILE_FORM_COMPANY_PLACEHOLDER: 'Enter your company name',
  MY_PROFILE_FORM_COMPANY_LABLE: 'Company',
  MY_PROFILE_FORM_STATUS_LABEL: 'Status',
  MY_PROFILE_FORM_STATUS_PLACEHOLDER: 'Status',
  
  MY_PROFILE_FORM_TITLE_LABEL: 'Title',
  MY_PROFILE_FORM_TITLE_PLACEHOLDER: 'Enter your title',
  MY_PROFILE_FORM_TITLE_REQUIRED: 'Title is required',

  MY_PROFILE_FORM_PHONE_LABEL: 'Phone',
  MY_PROFILE_FORM_PHONE_PLACEHOLDER: 'Enter your Phone Number',
  MY_PROFILE_FORM_PHONE_REQUIRED: 'Phone Number is required',


  MY_PROFILE_SAVE_CHANGES_BTN: 'Update Details',
  PROFILE_SIDE_PANEL_TILE: 'Edit Profile',
  PROFILE_SIDEPANEL_TABS: {
    GENERAL: {
      TAB_TITLE: 'General',
      DESCRIPTION: 'Update your username and manage your account.',
      KEY: 'general'
    },
    PASSWORD: {
      TAB_TITLE: 'Password',
      DESCRIPTION: 'Manage your password.',
      KEY: 'password'
    },
    MANAGE_SUBSCRIPTIONS: {
      TAB_TITLE: 'Manage Subcriptions',
      DESCRIPTION: 'Effortlessly control and tailor your subcription preferences with our Subscription Management Feature.',
      KEY: 'manage_subscriptions'
    },
    USERS: {
      TAB_TITLE: 'Users',
      DESCRIPTION: 'Users List',
      KEY: 'users'
    }
  },
  PAYMENT_METHODS_TAB: {
    TITLE: 'Payment Methods',
    KEY: 'payment_methods',
  },
  VIEW_BILLING_HISTORY: {
    TITLE: 'View Billing History',
    KEY: 'view_billing_history',
  },
  USERS: {
    HEADERS: [
      { NAME: 'First Name', ID: 'first_name' },
      { NAME: 'Last Name', ID: 'last_name' },
      { NAME: 'Email', ID: 'email' },
    ],
  }, 
  BILLING_HISTORY_HEADERS: [
    { NAME: 'Transaction Id', key: 'transaction_id'},
    { NAME: 'Amount', key: 'transaction_id'},
    { NAME: 'Date & Time', key: 'transaction_id'},
    { NAME: 'Status', key: 'transaction_id'},
    // { NAME: 'icon', key: 'sort'}
  ],

  TAB_DESCRIPTION: {
    general: 'Update your username and manage your account', 
    password: 'Manage your password',
    manage_subscriptions: 'Effortlessly control and tailor your subscription preferences with our Subscription Management feature',
    users: 'Users List'
  },
  SUBSCRIPTION_PLAN: {
    month: 'Per Monthly',
    year: 'Per Yearly'
  }
});
