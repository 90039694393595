import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import { Formik } from 'formik';
import AppButton from '../styles/ButtonLink.styled';
import AppInput from '../common/input/AppInput';
import MyProfileHelper from '../../helpers/features/MyProfileHelper';
import { useMyProfile } from '../../containers/myProfile/MyProfileContext';
import ErrorNotification from '../common/errorNotification/ErrorNotification';

function MyProfileForm() {
  const userProfile = useMyProfile();

  const profileSchema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    company: yup.string().required('Company is required'),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: userProfile.userDetails.id || '',
        email: userProfile.userDetails.email || '',
        first_name: userProfile.userDetails.first_name || '',
        last_name: userProfile.userDetails.last_name || '',
        company: userProfile.userDetails.company || '',
      }}
      onSubmit={async values => {
        userProfile.handleUpdateUserDetails(values, values.id);
      }}
      validationSchema={profileSchema}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        // eslint-disable-next-line no-unused-vars
        validateOnMount,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col md={6}>
              <AppInput
                label={MyProfileHelper.MY_PROFILE_FORM_FIRST_NAME_LABLE}
                requied="*"
                type="text"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_FIRST_NAME_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                name="first_name"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.first_name}
                errorText={errors.first_name && touched.first_name ? errors.first_name : null}
              />
              <AppInput
                label={MyProfileHelper.MY_PROFILE_FORM_LAST_NAME_LABLE}
                requied="*"
                type="text"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_LAST_NAME_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                name="last_name"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.last_name}
                errorText={errors.last_name && touched.last_name ? errors.last_name : null}
              />
              <AppInput
                label={MyProfileHelper.MY_PROFILE_FORM_COMPANY_LABLE}
                requied="*"
                type="text"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_EMAIL_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                name="company"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.company}
                errorText={errors.company && touched.company ? errors.company : null}
              />
              <AppInput
                className="m-0"
                label={MyProfileHelper.MY_PROFILE_FORM_EMAIL_LABLE}
                requied="*"
                type="email"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_EMAIL_PLACEHOLDER}
                helpText={MyProfileHelper.MY_PROFILE_FORM_EMAIL_HELP_TEXT}
                helpTextLink={MyProfileHelper.MY_PROFILE_FORM_EMAIL_HELP_TEXT_LINK}
                linkUrl="www.facebook.com"
                name="email"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.email}
                errorText={errors.email && touched.email ? errors.email : null}
                disabled
              />
              <AppButton disabled={!(dirty && isValid)}>
                {MyProfileHelper.MY_PROFILE_BTN_TEXT}
              </AppButton>
            </Col>
          </Row>
          {userProfile.message.type === 'error' && (
            <ErrorNotification errorMessage={userProfile.message.message} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default MyProfileForm;
