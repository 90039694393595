import { NavLink } from "react-router-dom";
import routes from "../../../helpers/routes";
import UserIcon from '../../../assets/images/users.svg';
import ActiveUserIcon from '../../../assets/images/active-user.svg';
import RegulationsIcon from '../../../assets/images/regulations.svg';
import ActiveRegulationsIcon from '../../../assets/images/active-regulations.svg';
import { StyledAdminSideBar } from "./AdminSideBar.Styled";

const AdminSideBar = (props) => {
  return (
    <StyledAdminSideBar>
      {/* <NavLink
        to={routes.AUTHENTICATED.ADMIN.USERS.PATH}
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <img alt="Users" src={UserIcon} className="mb-1 user-icon"/> 
        <img alt="Active-Users" src={ActiveUserIcon} className="mb-1 active-user"/>
        <span>Users</span>
      </NavLink> */}
      <NavLink
        to={routes.AUTHENTICATED.ADMIN.REGULATIONS.PATH}
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <img alt="Regulations" src={RegulationsIcon} className="mb-1 regulations"/>        
        <img alt="Active-Regulations" src={ActiveRegulationsIcon} className="mb-1 active-regulations"/>
        <span>Regulations</span>
      </NavLink>
    </StyledAdminSideBar>
  );
};

export default AdminSideBar;
