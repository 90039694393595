import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import store from '../../store/store';
import { clearSidebarReducer } from '../../store/actions/sidebar';
import { clearSearchReducer } from '../../store/actions/search';

const AuthenticationRoute = () => {
    const auth = useAuth();
    const location = useLocation();

    useEffect(() => {
        try {
            store.dispatch(clearSidebarReducer());
            store.dispatch(clearSearchReducer());
            const encodedAccessToken = location.search.split('token=')[1];
            const decodedToken = decodeURIComponent(encodedAccessToken);
            auth.persistToken(decodedToken);
          } catch (error) {
            console.error('Error parsing or decoding token:', error);
          }
    }, [location.search]);

    return null;
}

export default AuthenticationRoute;
