import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import StyleForm from './Form.styled';

function AppInput(props) {
  const {
    customClass,
    label,
    requied,
    tooltipContent,
    iconSrc,
    type,
    placeholder,
    errorText,
    helpText,
    linkUrl,
    helpTextLink,
    name,
    value,
    onChangeInput,
    onBlurInput,
    disabled,
  } = props;
  return (
    <StyleForm>
      <Form.Group className={`form-group ${customClass}`} controlId="formBasicEmail">
        <Form.Label>
          {label}
          <span className="red"> {requied}</span>
          <OverlayTrigger
              trigger={["hover", "focus"]} 
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {tooltipContent}
                </Tooltip>
              }
            >
              <Image src={iconSrc} className="ms-1 mb-1"/>
            </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          disabled={disabled}
        />
        <Form.Text className="error-text">{errorText}</Form.Text>
        <Form.Text className="text-muted text-end">
          <small>{helpText} <Link to={linkUrl}>{helpTextLink}</Link></small>
        </Form.Text>
      </Form.Group>
    </StyleForm>
  );
}

AppInput.propTypes = {
  customClass: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  requied: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  helpTextLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AppInput.defaultProps = {
  errorText: null,
  disabled: false,
};

export default AppInput;
