import styled from 'styled-components';

export const StylePage = styled.div`
  // padding-top:  30px;
`;
export const StylePageHeader = styled.div`
  padding-bottom: 30px;
  h2 {
    font-size: 25px;
    font-weight: 900;
  }
`;
export const PageWrapper = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 74px;
  b {
    font-size: 14px;
    font-weight: 900;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;
